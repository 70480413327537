import { createContext } from "react";
import { Jwt } from "../codegen/graphql";
import { decodeJwt } from "jose";

export interface IGlowJwtContext {
  jwt?: Jwt;
  returningUser: boolean;
  setJwt: (x: Jwt | undefined) => void;
  themeMode: "light" | "dark";
  setThemeMode: (x: "light" | "dark") => void;
}

export const GlowJwtContext = createContext<IGlowJwtContext>({
  jwt: undefined,
  returningUser: false,
  setJwt: (x) => {},
  themeMode: "light",
  setThemeMode: (x) => {},
});

export const ExpandGlowJwt = (token?: string) => {
  if (!token) {
    return {
      profile: undefined,
      sms: undefined,
    };
  }
  const decodedToken = decodeJwt(token);

  if (decodedToken["vu:pf"]) {
    return {
      profile: decodedToken["vu:pf"] as string,
      sms: decodedToken["vu:sms"] as string,
    };
  }

  if (decodedToken["ep:pf"]) {
    return {
      profile: decodedToken["ep:pf"] as string,
      sms: undefined,
    };
  }

  return {
    profile: undefined,
    sms: undefined,
  };
};
