import { Box, Typography, useMediaQuery } from "@mui/material";
import { GlowRowBox } from "./GlowBox";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ThemeExtensions } from "../themes/ThemeExtensions";


export const GlowLocationLink: React.FC<{
    locationName: string;
    locationMapUrl?: string;
    locationAddress?: string;
    hideIcon?: boolean;
  }> = (props) => {
    const isMobileSize = useMediaQuery("(max-width:767px)");
    var addressDisplay = props.locationAddress;
    if (addressDisplay && addressDisplay.endsWith(", USA")) {
      addressDisplay = addressDisplay.substring(
        0,
        addressDisplay.length - ", USA".length
      );
    }
  
    return (
      <GlowRowBox
        onClick={
          props.locationMapUrl
            ? () => {
                if (isMobileSize) {
                  window.location.href = props.locationMapUrl!;
                } else {
                  window.open(props.locationMapUrl!, "_blank");
                }
              }
            : undefined
        }
        sx={{
          cursor: props.locationMapUrl ? "pointer" : "default",
          gap: "5px",
          margin: "0px",
          padding: "0px",
          justifyContent: "left",
        }}
      >
        {props.locationMapUrl && (props.hideIcon == undefined || props.hideIcon == false) && (
          <LocationOnIcon
            sx={{
              color: (t) => ThemeExtensions(t).text.accent,
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "left",
          }}
        >
          <Typography variant="body1">{props.locationName}</Typography>
          {addressDisplay && (
            <Typography
              variant="caption"
              color={(t) => ThemeExtensions(t).text.accent}
            >
              {addressDisplay}
            </Typography>
          )}
        </Box>
      </GlowRowBox>
    );
  };
  