import {
  Box,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface GlowAuthCodeEntryProps {
  code: string;
  inputFocusRef?: React.RefObject<HTMLInputElement>;
  focusHint?: boolean;
  error?: boolean;
}

export const GlowAuthCodeEntry: React.FC<GlowAuthCodeEntryProps> = (props) => {
  const MAX_LENGTH = 6;
  const scrollRef = useRef<HTMLInputElement>(null);

  const handleInputFocus = () => {
    const inputNode = scrollRef.current;
    if (inputNode) {
      const inputTop = inputNode.getBoundingClientRect().top;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollTarget = inputTop + scrollTop - window.innerHeight / 3;
      window.scrollTo({ top: scrollTarget, behavior: "smooth" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.focusHint) {
        handleInputFocus();
      }
    }, 100);
  }, [props.focusHint]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          minWidth: "300px",
          maxWidth: "300px",
        }}
      >
        {Array.from(Array(MAX_LENGTH).keys()).map((i) => {
          const value = props.code.charAt(i);
          return (
            <TextField
              ref={i == 0 ? scrollRef : undefined}
              inputProps={{
                sx: {
                  textAlign: "center",
                  padding: "0px",
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: 100,
                  padding: "10px",
                  textAlign: "center",
                },
              }}
              focused={i === props.code.length && document.activeElement===props.inputFocusRef?.current}
              error={props.error}
              key={i}
              onClick={(x) => {
                props.inputFocusRef?.current?.focus();
              }}
              value={value}
            />
          );
        })}
      </Box>
    </>
  );
};
