import { Box, BoxProps, styled } from "@mui/material";


export const GlowColumnBox : React.FC<BoxProps> = (props) => {
    return <Box 
        {...props}
        sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
            margin: 2,
            ...props.sx,
        }}
    />
}

export const GlowRowBox : React.FC<BoxProps> = (props) => {
    return <Box 
        {...props}
        sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
            margin: 2,
            ...props.sx,
        }}
    />
}