import { Paper, PaperProps, useTheme } from "@mui/material";

export const GlowSecondaryPaper: React.FC<PaperProps> = (props) => {
  const theme = useTheme();
  return (
    <Paper
      variant="elevation"
      {...props}
      sx={{
        // backgroundColor: `${theme.palette.secondary.main}23`,
        backgroundColor: (theme) => `${theme.palette.secondary.main}23`,
        borderRadius: "28px",
        ...props.sx,
      }}
    />
  );
};
