import { Button, Dialog, Typography } from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "./GlowBox";

interface ConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onClose?: () => void;
  bias: "confirm" | "cancel";
  message?: string;
  header?: string;
  okText?: string;
  cancelText?: string;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props
) => {
  return (
    <Dialog open={props.open} onClose={props.onClose ?? props.onCancel}>
      <GlowColumnBox>
        {props.header && <Typography variant="h6">{props.header}</Typography>}
        <Typography variant="subtitle1">
          {props.message ?? "Are you sure?"}
        </Typography>
        <GlowRowBox>
          <Button
            variant={props.bias == "confirm" ? "contained" : "outlined"}
            onClick={props.onConfirm}
          >
            {props.okText ?? "Ok"}
          </Button>
          <Button
            variant={props.bias == "cancel" ? "contained" : "outlined"}
            onClick={props.onCancel}
          >
            {props.cancelText ?? "Cancel"}
          </Button>
        </GlowRowBox>
      </GlowColumnBox>
    </Dialog>
  );
};
