import {
  Button,
  Checkbox,
  Dialog,
  Box,
  Slide,
  Typography,
  Link,
  FormGroup,
  TextField,
  FormControlLabel,
} from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "./GlowBox";
import { RSVPRow } from "./RSVPRow";
import { GlowTextField } from "./GlowTextField";
import { GlowPhoneField } from "./GlowPhoneField";
import { useContext, useEffect, useRef, useState } from "react";
import { GlowJwtContext, IGlowJwtContext } from "../../jwt/GlowJWTContext";
import { Jwt, PrincipalType, ReservationStatus } from "../../codegen/graphql";
import { GlowFormBox } from "./GlowFormBox";
import {
  COMPLETE_LOGIN_V3,
  RESEND_CODE_V3,
  START_LOGIN_V3,
  VerifyView,
} from "../smart-components/SignUpOrLoginDialog";
import { useMutation } from "@apollo/client";

interface RSVPClickThroughDiaglogProps {
  open: boolean;
  onClose: () => void;
  status: ReservationStatus;
  showWaitlist: boolean;
  onStatusChange: (x: ReservationStatus) => void;
  onRSVPViaVerified: (jwt: Jwt, plusOne: boolean) => void;
  onRSVPEphemeral: (name: string) => void;
}

const RSVPClickThroughContents: React.FC<RSVPClickThroughDiaglogProps> = (
  props
) => {
  const context = useContext(GlowJwtContext);

  const getDefaultFromContext = (ctxt: IGlowJwtContext) => {
    if (ctxt.jwt?.principalType == PrincipalType.Verified) {
      return "verified";
    }
    if (ctxt.returningUser) {
      return "sign-in";
    }
    return "sign-up";
  };

  const [verifyCode, setVerifyCode] = useState<boolean>(false);
  const [mode, setMode] = useState<
    "verified" | "sign-in" | "sign-up" | "ephemeral"
  >(getDefaultFromContext(context));

  const [failedLoginForName, setFailedLoginForName] = useState(false);

  const [name, setName] = useState<string>("");
  const nameField = (
    <GlowTextField
      required
      label="Name"
      value={name}
      onChange={(x) => {
        setName(x.target.value);
      }}
    />
  );

  const [phone, setPhone] = useState<string>("");
  const phoneField = (
    <GlowPhoneField
      required
      value={mode != "ephemeral" ? phone : ""}
      disabled={mode == "ephemeral"}
      onChange={(x) => {
        setPhone(x);
      }}
    />
  );

  const [plusOne, setPlusOne] = useState<boolean>(false);
  const plusOneField = (
    <GlowRowBox
      sx={{
        width: "50%",
        margin: "0px",
        padding: "0px",
        justifyContent: "flex-start",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            disabled={props.status === ReservationStatus.No}
            checked={plusOne}
            onChange={(x) => setPlusOne(x.target.checked)}
          />
        }
        label="Bringing a +1"
      />
    </GlowRowBox>
  );

  const [tempToken, setTempToken] = useState<string>("");
  const [startLogin, startLoginState] = useMutation(START_LOGIN_V3, {
    context: {
      headers: {},
    },
    variables: {
      sms: phone.replaceAll(" ", ""),
      name: mode == "sign-up" ? name : "existing_user",
      returningUser: mode == "sign-in" ? true : false,
    },
    onCompleted: (data) => {
      setTempToken(data.startLogin.token);
      setVerifyCode(true);
    },
    onError: (err) => {
      if (err.message === "Must provide name for profile") {
        setMode("sign-up");
        setFailedLoginForName(true);
      }
    },
  });
  const rspvRow = (
    <RSVPRow selected={props.status} onChange={props.onStatusChange}  showWaitlist={props.showWaitlist} />
  );
  const rsvpLoginButton = (
    <Button
      type="submit"
      variant="contained"
      onClick={() => {
        codeRef.current?.focus();
      }}
    >
      Send RSVP
    </Button>
  );

  const rsvpGuestButton = (
    <Button
      variant="contained"
      onClick={() => {
        codeRef.current?.focus();
      }}
    >
      Send RSVP
    </Button>
  );
  const header = <Typography variant="h6">Send RSVP</Typography>;

  // Code Stuff:
  const [code, setCode] = useState("");
  const codeRef = useRef<HTMLInputElement>(null);

  const createAccountField = (
    <>
      <GlowRowBox
        sx={{
          width: "100%",
          margin: "0px",
          padding: "0px",
          justifyContent: "flex-start",
        }}
      >
        {/* <Checkbox
          checked={mode != "ephemeral"}
          onChange={(x) => {
            if (x.target.checked) {
              setMode(
                context.jwt?.principalType == PrincipalType.Verified
                  ? "verified"
                  : context.returningUser
                  ? "sign-in"
                  : "sign-up"
              );
            } else {
              setMode("ephemeral");
            }
          }}
        />
        <Typography variant="caption" sx={{ maxWidth: "80%" }}>
          Create a free Glow account to track your RSVPs and organize future
          events
        </Typography> */}
      </GlowRowBox>
    </>
  );

  const signUpOrEphemeralRSVPView = (
    <Box
      sx={{
        position: "absolute",
        padding: "8px",
      }}
    >
      <GlowFormBox
        inDialog
        onSubmit={(e) => {
          e.preventDefault();
          setResendSeconds(30);

          if (mode == "ephemeral") {
            props.onRSVPEphemeral(name);
          } else {
            startLogin();
          }
        }}
      >
        {header}
        <Typography variant="body1">
          Create a free Glow account to RSVP
        </Typography>
        {failedLoginForName ? (
          <>
            <Typography> We need your name for login</Typography>
          </>
        ) : (
          <></>
        )}
        <FormGroup sx={{ gap: 2 }}>
          {rspvRow}
          {nameField}
          {phoneField}
          {createAccountField}
          {plusOneField}
        </FormGroup>
        {rsvpLoginButton}
        <Typography variant="caption">
          Already have an account?{" "}
          <Link onClick={() => setMode("sign-in")}>Sign in</Link>
        </Typography>
      </GlowFormBox>
    </Box>
  );

  const signInAndRSVPView = (
    <Box
      sx={{
        position: "absolute",
        padding: "8px",
      }}
    >
      <GlowFormBox
        inDialog
        onSubmit={(e) => {
          e.preventDefault();
          startLogin();
          setResendSeconds(30);
        }}
      >
        <Typography variant="h6">Welcome back</Typography>
        <Typography variant="body1">
          Sign in using your phone number to RSVP
        </Typography>
        <FormGroup sx={{ gap: 2 }}>
          {rspvRow}
          {phoneField}
          {plusOneField}
        </FormGroup>
        {rsvpLoginButton}

        <Typography variant="caption">
          Don't have an account?{" "}
          <Link onClick={() => setMode("sign-up")}>Sign up</Link>
        </Typography>
      </GlowFormBox>
    </Box>
  );

  // const ephemeralRSVP = (
  //   <Box
  //     sx={{
  //       position: "absolute",
  //       padding: "8px",
  //     }}
  //   >
  //     <GlowFormBox
  //       inDialog
  //       onSubmit={(e) => {
  //         e.preventDefault();
  //         props.onRSVPEphemeral(name)
  //       }}
  //     >
  //       {header}
  //       <FormGroup sx={{ gap: 2 }}>
  //         {rspvRow}
  //         {nameField}
  //         {createAccountField}
  //         {plusOneField}
  //       </FormGroup>
  //       {rsvpLoginButton}
  //       <Typography variant="caption">
  //         Already have an account?{" "}
  //         <Link onClick={() => setMode("sign-in")}>Sign in</Link>
  //       </Typography>
  //     </GlowFormBox>
  //   </Box>
  // );

  const [completeChallenge, completeChallengeState] =
    useMutation(COMPLETE_LOGIN_V3);
  const setAuthCodeAndAutoSubmit = (code: string) => {
    // max token length is 6.
    if (code.length > 6) {
      return;
    }

    setCode(code);

    // If the code is 6 digits, submit it.
    if (code.length === 6) {
      completeChallenge({
        // Override the auth credential.
        context: {
          headers: {
            authorization: "Bearer " + tempToken,
          },
        },
        variables: {
          confirmCode: code,
        },

        onCompleted: (x) => {
          context.setJwt(x.completeLogin);
          props.onRSVPViaVerified(x.completeLogin, plusOne);
        },
      });
    }
  };

  const [resendCode, resendCodeState] = useMutation(RESEND_CODE_V3);
  const [resendSeconds, setResendSeconds] = useState(30);
  const intervalRef = useRef<NodeJS.Timer | null>();
  useEffect(() => {
    if (intervalRef.current) {
    } else {
      intervalRef.current = setInterval(() => {
        setResendSeconds((x) => x - 1);
      }, 1000);
    }
  });

  const verifyView = (
    <Box
      sx={{
        position: "absolute",
        padding: "8px",
      }}
    >
      <VerifyView
        code={code}
        inputFocusRef={codeRef}
        error={false}
        loading={false}
        smsNumber={phone}
        back={() => setVerifyCode(false)}
        resendSeconds={resendSeconds}
        resendCode={() => {
          setResendSeconds(30);
          // if(intervalRef.current) {
          //   clearInterval(intervalRef.current);
          // }
          // intervalRef.current = setInterval(()=>{
          //   setResendSeconds((x) => x - 1);
          // }, 1000);
          resendCode({
            context: {
              headers: {
                authorization: "Bearer " + tempToken,
              },
            },
            onCompleted(data) {
              setTempToken(data.resendCode.token);
            },
          });
        }}
      />
    </Box>
  );

  return (
    <>
      <TextField
        inputRef={codeRef}
        value={code}
        type="number"
        inputMode="numeric"
        autoComplete="one-time-code"
        onChange={(x) => setAuthCodeAndAutoSubmit(x.target.value)}
        // disabled={props.disabled}
        inputProps={{ pattern: "[0-9]*" }}
        sx={{
          position: "absolute",
          top: -4999,
          left: -4999,
        }}
      />
      <Slide
        in={(mode == "sign-up" || mode == "ephemeral") && !verifyCode}
        direction="right"
        mountOnEnter
        unmountOnExit
      >
        {signUpOrEphemeralRSVPView}
      </Slide>
      <Slide
        in={mode == "sign-in" && !verifyCode}
        direction="right"
        mountOnEnter
        unmountOnExit
      >
        {signInAndRSVPView}
      </Slide>
      {/* <Slide
        in={mode == "ephemeral" && !verifyCode}
        direction="right"
        mountOnEnter
        unmountOnExit
      >
        {ephemeralRSVP}
      </Slide> */}
      <Slide in={verifyCode} direction="left">
        {verifyView}
      </Slide>
    </>
  );
};

export const RSVPClickThroughDiaglog: React.FC<RSVPClickThroughDiaglogProps> = (
  props
) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          margin: "0px",
          height: "480px",
          width: "350px",
          overflow: "clip",
        },
      }}
    >
      <GlowColumnBox
        sx={{
          height: "480px",
          width: "350px",
          margin: "0px",

          justifyContent: "center",
        }}
      >
        <RSVPClickThroughContents {...props} />
      </GlowColumnBox>
    </Dialog>
  );
};
