import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { GlowJwtContext } from "../../jwt/GlowJWTContext";

export const ConstCreateAccountOrSignInSnack: React.FC<{
  open: boolean;
  onClose: () => void;
  body?: string;
}> = (props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.onClose();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [props.open]);
  const glowJwtContext = useContext(GlowJwtContext);

  const title = glowJwtContext.returningUser
    ? "Sign-in to continue!"
    : "Create account to continue!";

  const subtitle = glowJwtContext.returningUser
    ? "Welcome back!"
    : "Welcome to Glow!";

  const body =
    props.body ??
    (glowJwtContext.returningUser
      ? "Sign-in to your account with Glow to create a poll!"
      : "Create a free account with Glow to create a poll!");

  return (
    <Box
      sx={{
        position: "fixed",
        top: "40px",
        right: "50px",
      }}
    >
      <Tooltip
        open={props.open}
        arrow
        componentsProps={{
          arrow: {
            sx: {
              // color: t=>ThemeExtensions(t as Theme).palette.extraPaperColor
            },
          },
          tooltip: {
            sx: {
              margin: "10px",
              marginRight: "20px",
              // border: "1px solid black",
              // backgroundColor: t=>ThemeExtensions(t as Theme).palette.extraPaperColor
            },
          },
        }}
        title={
          <Box
            sx={{
              maxWidth: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
            <Typography textAlign="center">{body}</Typography>
          </Box>
        }
      >
        <Box></Box>
      </Tooltip>
    </Box>
  );
};
