import {
  ApolloClient,
  DocumentNode,
  HttpLink,
  InMemoryCache,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";

const sanityClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://hc8nkf7c.api.sanity.io/v1/graphql/production/default",
  }),
  cache: new InMemoryCache(),
});

export function useSanityQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  defaultValue: TData,
  options?: QueryHookOptions<TData, TVariables>
): { loading: boolean; error?: any; data: TData, defaultData: TData } {
  const queryResult = useQuery(query, {
    ...options,
    client: sanityClient,
  });

  return {
    loading: queryResult.loading,
    error: queryResult.error,
    data: queryResult.data ?? defaultValue,
    defaultData: defaultValue,
  };
}
