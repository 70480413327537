import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  SxProps,
  Typography,
} from "@mui/material";
import { GlowColumnBox, GlowRowBox } from "./GlowBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GlowTextField } from "./GlowTextField";

import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { ReservationStatus } from "../../codegen/graphql";
import { GetTextForStatus } from "../../util/status";
import { GlowSecondaryPaper } from "./GlowSecondaryPaper";
import { TopLeftBackButton } from "./TopLeftBackButton";

export interface TextGuestDialogRSVP {
  profileId: string;
  name: string;
  phone10?: string | null | undefined;
  currentUser: boolean;
  status: ReservationStatus;
}

export interface TextGuestDialogProps {
  open: boolean;
  onClose: () => void;
  yesRSVPs: TextGuestDialogRSVP[];
  noRSVPS: TextGuestDialogRSVP[];
  maybeRSVPs: TextGuestDialogRSVP[];
  waitlistRSVPs: TextGuestDialogRSVP[];
}

export const TextGuestDialog: React.FC<TextGuestDialogProps> = (props) => {
  const [message, setMessage] = useState<string>("");

  const guestCount = [
    ...props.yesRSVPs,
    ...props.noRSVPS,
    ...props.maybeRSVPs,
    ...props.waitlistRSVPs,
  ].filter((x) => !x.currentUser).length;

  const defaultSelected = new Set<string>();
  if (props.yesRSVPs.length > 0) {
    const yesPhones = props.yesRSVPs
      .filter((x) => x.phone10 && !x.currentUser)
      .map((x) => x.phone10!);
    yesPhones.forEach((phone) => {
      defaultSelected.add(phone);
    });
  }
  if (props.waitlistRSVPs.length) {
    // if there is a waitlist; we don't opt in tenatives
    // Why? That's what Austin said to do :-P
  } else if (props.maybeRSVPs) {
    const maybePhones = props.maybeRSVPs
      .filter((x) => x.phone10 && !x.currentUser)
      .map((x) => x.phone10!);
    maybePhones.forEach((phone) => {
      defaultSelected.add(phone);
    });
  }

  const [selectedPhones, setSelectedPhones] = useState<string[]>(
    Array.from(defaultSelected)
  );

  const addPhones = (phones: string[]) => {
    const currentPhones = [...selectedPhones, ...phones];
    const unique = new Set(currentPhones);
    setSelectedPhones(Array.from(unique));
  };

  const removePhones = (phones: string[]) => {
    const currentPhones = new Set(selectedPhones);
    phones.forEach((phone) => {
      currentPhones.delete(phone);
    });
    setSelectedPhones(Array.from(currentPhones));
  };

  const renderAccordion = (
    category: string,
    allRsvps: TextGuestDialogRSVP[]
  ) => {
    const rsvps = allRsvps
      .filter((x) => x.phone10)
      .filter((x) => !x.currentUser);
    const checkBoxSx: SxProps = {
      padding: "0px",
    };

    if (rsvps.length == 0) {
      return <> </>;
    }

    const allPhones = rsvps
      .filter((x) => x.phone10)
      .map((rsvp) => rsvp.phone10!);
    const unselctedPhones = allPhones.filter(
      (phone) => !selectedPhones.includes(phone!)
    );
    const allPhonesSelected = unselctedPhones.length == 0;
    const somePhonesSelected =
      unselctedPhones.length > 0 && unselctedPhones.length < rsvps.length;

    return (
      <Accordion
        disabled={rsvps.length == 0}
        sx={{
          width: "100%",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Checkbox
            checked={allPhonesSelected}
            indeterminate={somePhonesSelected}
            sx={checkBoxSx}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              console.log("clicked");
              e.stopPropagation();
              if (allPhonesSelected) {
                removePhones(allPhones);
              } else if (somePhonesSelected) {
                addPhones(unselctedPhones);
              } else {
                addPhones(allPhones);
              }
            }}
          />
          <Typography
            sx={{
              paddingLeft: "5px",
            }}
            variant="subtitle2"
            color="primary"
          >
            {category}
          </Typography>
        </AccordionSummary>

        <Grid container spacing={1}>
          {/* FIX SPACING */}
          {rsvps.map((rsvp, ndx) => (
            <React.Fragment key={ndx}>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <FormControlLabel
                  label={rsvp.name}
                  control={
                    <Checkbox
                      sx={{ ...checkBoxSx, marginRight: "5px" }}
                      checked={selectedPhones.includes(rsvp.phone10 ?? "X")}
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          if (rsvp.phone10) {
                            addPhones([rsvp.phone10]);
                          }
                        } else {
                          if (rsvp.phone10) {
                            removePhones([rsvp.phone10]);
                          }
                        }
                      }}
                    />
                  }
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Accordion>
    );
  };

  const messageLink = `sms:/open?addresses=${selectedPhones.join(
    ","
  )}&body=${encodeURI(message)}`;
  const manualRSVPs = [
    ...props.yesRSVPs,
    ...props.noRSVPS,
    ...props.maybeRSVPs,
    ...props.waitlistRSVPs,
  ].filter((x) => !x.phone10);

  return (
    <>
      <GlowColumnBox>

        <Typography color="primary" variant="subtitle1">
          Text Guests
        </Typography>

        {guestCount == 0 && (
          <GlowSecondaryPaper
            sx={{
              width: "100%",
            }}
          >
            <GlowRowBox>
              <InfoRoundedIcon color="secondary" />
              <Typography variant="body2">
                No guests have submitted an RSVP, once they have, you can
                quickly text them here.
              </Typography>
            </GlowRowBox>
          </GlowSecondaryPaper>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {renderAccordion("Yes", props.yesRSVPs)}
          {renderAccordion("Waitlist", props.waitlistRSVPs)}
          {renderAccordion("Tentative", props.maybeRSVPs)}
          {renderAccordion("No", props.noRSVPS)}
          {manualRSVPs.length > 0 && (
            <Accordion
              sx={{
                width: "100%",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{
                    paddingLeft: "5px",
                  }}
                  variant="subtitle2"
                  color="primary"
                >
                  Manually Added Guests
                </Typography>
              </AccordionSummary>
              <GlowColumnBox
                sx={{
                  alignItems: "flex-start",
                }}
              >
                <GlowSecondaryPaper>
                  <GlowRowBox>
                    <InfoRoundedIcon color="secondary" />
                    <Typography variant="body2">
                      The guests below were manually added by a host; we don't
                      have their phone numbers.
                    </Typography>
                  </GlowRowBox>
                </GlowSecondaryPaper>
                {manualRSVPs.map((rsvp, ndx) => (
                  <Typography key={ndx} variant="body2">
                    {rsvp.name} - {GetTextForStatus(rsvp.status)}
                  </Typography>
                ))}
              </GlowColumnBox>
            </Accordion>
          )}
        </Box>
      </GlowColumnBox>
      <Box
        sx={(t) => {
          return {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            padding: "10px",
            backgroundColor: t.palette.background.default,
            alignItems: "center",
          };
        }}
      >
        <GlowTextField
          multiline
          value={message}
          onChange={(x) => setMessage(x.target.value)}
          placeholder="Type your message here... "
        />
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
          }}
          href={messageLink}
          disabled={selectedPhones.length <= 0}
        >
          <SendRoundedIcon
            color={selectedPhones.length > 0 ? "primary" : undefined}
          />
        </IconButton>
      </Box>
    </>
  );
};

interface TextGuestSummaryProps {
  yesRSVPs: TextGuestDialogRSVP[];
  noRSVPS: TextGuestDialogRSVP[];
  maybeRSVPs: TextGuestDialogRSVP[];
  waitlistRSVPs: TextGuestDialogRSVP[];
}

export const TextGuestSummary: React.FC<TextGuestSummaryProps> = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <GlowSecondaryPaper>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <TextGuestDialog
          open={open}
          onClose={() => setOpen(false)}
          yesRSVPs={props.yesRSVPs}
          noRSVPS={props.noRSVPS}
          maybeRSVPs={props.maybeRSVPs}
          waitlistRSVPs={props.waitlistRSVPs}
        />
      </Dialog>
      <GlowColumnBox>

        <Typography variant="h5" color="primary">
          Text Guests
        </Typography>
        <Typography variant="body1">
          You can quickly send a text message to your guests here.
        </Typography>

        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
        >
          Text
        </Button>
      </GlowColumnBox>
    </GlowSecondaryPaper>
  );
};
