import {
  Box,
  Button,
  Dialog,
  Link,
  MenuItem,
  MenuList,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { CalendarPicker } from "@mui/x-date-pickers";
import moment from "moment-timezone";
import React, { useEffect, useRef } from "react";
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";

import { useState } from "react";
import { GlowTextField } from "./GlowTextField";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import { GlowFormBox } from "./GlowFormBox";

export enum DateTimeCloseReason {
  TimePicked = 1,
  Cancelled = 2,
}
interface GlowDateTimeContentsProps {
  open: boolean;
  onClose: (reason: DateTimeCloseReason, time: moment.Moment) => void;
  time: moment.Moment;
  timezone: string;
  onChange: (time: moment.Moment, timezone: string) => void;
  keepMounted?: boolean;
}

/**
 * Using this so that lifecycle of dialog contents is surrounded by dialog.
 */
const DateTimePickerContents: React.FC<GlowDateTimeContentsProps> = (props) => {
  const updateTime = (hours: number, minutes: number) => {
    const time = moment(
      props.time.clone().tz(props.timezone).hours(hours).minutes(minutes)
    );
    props.onChange(time, props.timezone);
    return time;
  };
  const selectedTimeRef = React.useRef<HTMLLIElement>(null);
  const [datePicked, setDatePicked] = useState(false);
  const [timePicked, setTimePicked] = useState(false);
  const [timezoneDialogOpen, setTimezoneDialogOpen] = useState(false);
  const timeZoneRender = moment().tz(props.timezone).format("z");

  const zonedTime = props.time.clone().tz(props.timezone);

  const usZones = moment.tz.zonesForCountry("US");
  const dummyTime = moment();
  const usZonesSet = new Set(
    usZones.map((x) => {
      dummyTime.tz(x).format("z");
    })
  );

  var timeZoneStructure: {
    key: string;
    offset: string;
    abbr: string;
    longName?: string;
    cities: string[];
    zones: string[];
    displayCity: string;
  }[] = [];

  usZones.forEach((x) => {
    const offset = moment().tz(x).format("Z");
    const abbr = moment().tz(x).format("z");
    const longName = moment().tz(x).format("zz");
    const parts = x.split("/");
    const city = parts[parts.length - 1];
    const existing = timeZoneStructure.find((y) => y.abbr === abbr);
    if (existing) {
      existing.cities.push(city);
      existing.zones.push(x);
    } else {
      const displayCityOverride = new Map<string, string>([
        ["EDT", "New York"],
        ["EST", "New York"],
        ["CDT", "Chicago"],
        ["CST", "Chicago"],
        ["MDT", "Denver"],
        ["MST", "Phoenix"],
        ["PDT", "Los Angeles"],
        ["PST", "Los Angeles"],
        ["AKDT", "Anchorage"],
        ["AKST", "Anchorage"],
      ]);

      const cityOverride = displayCityOverride.get(abbr) || city;
      timeZoneStructure.push({
        key: abbr,
        offset: offset,
        abbr: abbr,
        longName: longName === abbr ? undefined : longName,
        cities: [city],
        displayCity: cityOverride,
        zones: [x],
      });
    }
  });

  timeZoneStructure = timeZoneStructure.sort((a, b) => {
    return a.offset.localeCompare(b.offset);
  });

  const localGuess = moment.tz.guess();
  const localTsz = {
    key: "local",
    offset: moment().tz(localGuess).format("Z"),
    abbr: moment().tz(localGuess).format("z"),
    longName: moment().tz(localGuess).format("zz"),
    displayCity: "Local Time",
    cities: [],
    zones: [localGuess],
  };

  timeZoneStructure = [localTsz, ...timeZoneStructure];

  useEffect(() => {
    setTimeout(() => {
      selectedTimeRef.current?.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }, 100);
  }, [props.open, selectedTimeRef]);

  return (
    <>
      <Dialog
        open={timezoneDialogOpen}
        onClose={() => setTimezoneDialogOpen(false)}
        fullWidth
      >
        <GlowFormBox
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="h6">Select Time Zone</Typography>
          <MenuList
            sx={{
              maxHeight: "80vh",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            {timeZoneStructure.map((tsz) => {
              return (
                <MenuItem
                  selected={tsz.zones.includes(props.timezone)}
                  key={tsz.key}
                  onClick={() => {
                    props.onChange(props.time, tsz.zones[0]);
                    setTimezoneDialogOpen(false);
                  }}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography variant="body1">
                          {tsz.displayCity} ({tsz.abbr})
                        </Typography>
                        {tsz.longName && (
                          <Typography variant="caption">
                            {tsz.longName}
                          </Typography>
                        )}
                      </Box>
                      <Typography variant="body2">{tsz.offset}</Typography>
                    </Box>
                  </Box>
                </MenuItem>
              );
            })}
          </MenuList>
        </GlowFormBox>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CalendarPicker
          disablePast
          date={props.time}
          onChange={(x) => {
            if (x) {
              props.onChange(x!, props.timezone);
              setDatePicked(true);
            }
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "360px",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              minHeight: "36px",
              maxHeight: "36px",
              height: "30px",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            <Typography variant="body1">Times</Typography>
            <Typography variant="body2">
              <Link
                onClick={() => {
                  setTimezoneDialogOpen(true);
                }}
              >
                {timeZoneRender}
              </Link>
            </Typography>
          </Box>
          <MenuList
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "360px",
              overflowY: "scroll",
            }}
          >
            {Array.from(Array(24 * 2).keys()).map((x) => {
              const hours = Math.trunc(x / 2);
              const minutes = x % 2 == 0 ? 0 : 30;
              const selected =
                hours === zonedTime.hours() && minutes === zonedTime.minutes();

              return (
                <MenuItem
                  key={x}
                  selected={selected}
                  ref={selected ? selectedTimeRef : undefined}
                  id={""}
                  sx={{ textAlign: "right" }}
                  onClick={(event) => {
                    const t = updateTime(hours, minutes);
                    setTimePicked(true);

                    if (datePicked) {
                      props.onClose(DateTimeCloseReason.TimePicked, t);
                    }
                  }}
                >
                  {moment().hour(hours).minutes(minutes).format("h:mm a")}
                </MenuItem>
              );
            })}
          </MenuList>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Button
          onClick={() =>
            props.onClose(DateTimeCloseReason.TimePicked, props.time)
          }
        >
          Done
        </Button>
      </Box>
      {/* <Box>
                <Checkbox checked={datePicked} disabled />
                <Checkbox checked={timePicked} disabled />
            </Box> */}
    </>
  );
};

export const GlowDateTimeDialog: React.FC<GlowDateTimeContentsProps> = (
  props
) => {
  return (
    <Dialog
      keepMounted={
        props.keepMounted === undefined ? undefined : props.keepMounted
      }
      open={props.open}
      fullWidth={true}
      sx={{
        width: "100%",
        margin: "0px",
      }}
      maxWidth="xs"
      PaperProps={{
        sx: {
          width: "100%",
          margin: "10px",
        },
      }}
      onClose={() => props.onClose(DateTimeCloseReason.Cancelled, props.time)}
    >
      <DateTimePickerContents {...props} />
    </Dialog>
  );
};

interface GlowDateTimePickerProps {
  time: moment.Moment;
  timeZone: string;
  emptyUntilSet?: boolean;
  onChange: (t: moment.Moment, timeZone: string) => void;
  onTimeSelected?: (t: moment.Moment, timeZone: string) => void;
  resetOnSelect?: boolean;
  allowPast: boolean;
}

export const GlowDateTimePicker: React.FC<GlowDateTimePickerProps> = (
  props
) => {
  const [popoverOpen, setPoppoverOpen] = useState(false);
  const [beenSet, setBeenSet] = useState(false);
  const [firstFocused, setFirstFocus] = useState(true);
  const ref = useRef<HTMLInputElement>();

  const localTimezone = moment.tz.guess();
  const localOffset = props.time.clone().tz(localTimezone).utcOffset();
  const selectefOffset = props.time.clone().tz(props.timeZone).utcOffset();
  const sameTimezone = localOffset == selectefOffset;

  var display = props.time.format("MMMM Do YYYY, h:mm a");

  if (!sameTimezone) {
    var display = props.time
      .clone()
      .tz(props.timeZone)
      .format("MMMM Do YYYY, h:mm a (z)");
  }

  if (!beenSet && props.emptyUntilSet) {
    display = "";
  }
  return (
    <>
      <GlowDateTimeDialog
        open={popoverOpen}
        onClose={(reason, time) => {
          setPoppoverOpen(false);
          if (reason == DateTimeCloseReason.TimePicked) {
            props.onTimeSelected && props.onTimeSelected(time, props.timeZone);
            if (props.resetOnSelect) {
              setBeenSet(false);
            }
          }
        }}
        time={props.time}
        timezone={props.timeZone}
        onChange={(x, y) => {
          props.onChange(x, y);
          setBeenSet(true);
        }}
      />
      <GlowTextField
        error={props.time.isBefore(moment()) && !props.allowPast}
        helperText={
          props.time.isBefore(moment()) && !props.allowPast
            ? "Can't create events in the past"
            : undefined
        }
        onInvalid={(x) => {
          setFirstFocus(false);
        }}
        inputRef={ref}
        required
        autoComplete="off"
        focused={popoverOpen}
        value={display}
        label="Date & Time"
        contentEditable={false}
        onClick={(x) => {
          setPoppoverOpen(true);
        }}
        onFocus={(x) => {
          if (firstFocused) {
            setPoppoverOpen(true);
          }
          setFirstFocus(false);
        }}
        InputProps={{
          endAdornment: sameTimezone ? (
            <EventRoundedIcon />
          ) : (
            <QueryBuilderRoundedIcon color="warning" />
          ),
        }}
      ></GlowTextField>
    </>
  );
};
