import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const TopLeftBackButton: React.FC<{
  onClick: () => void;
}> = (props) => {
  const nav = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const listener = (e: PopStateEvent) => {
      console.log("popstate - lop left back button");
      props.onClick();
    };
    console.log("adding popstate listener");
    window.addEventListener("popstate", listener);
    return () => {
      console.log("removing popstate listener");
      window.removeEventListener("popstate", listener);
    };
  }, [location]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
      }}
    >
      <Button onClick={() => nav(-1)} startIcon={<ArrowBackIcon />} sx={{}}>
        Back
      </Button>
    </Box>
  );
};
