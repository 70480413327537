import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { Logo } from "../components/Logo";
import { useContext, useEffect, useState } from "react";
import { GlowSignUpOrLoginDialogX } from "./SignUpOrLoginDialog";
import { GlowJwtContext } from "../../jwt/GlowJWTContext";
import { PrincipalType } from "../../codegen/graphql";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PROFILE_QUERY } from "./ProfileView";
import { useQuery } from "@apollo/client";
import { GlowEventBus } from "../../messaging/eventBus";

const ProfileButton: React.FC = () => {
  const theme = useTheme();
  const jwtContext = useContext(GlowJwtContext);

  const { data, loading, error } = useQuery(PROFILE_QUERY);

  const [menuAnchorElement, setMenuAnchorElement] =
    React.useState<null | HTMLElement>(null);
  const nav = useNavigate();

  const nameWords = data?.getUserProfile.name.split(" ").filter((x) => x);
  const initials = nameWords?.map((x) => x[0]).join("") ?? "??";

  const firstInitial = initials[0];
  var lastInitial = initials[initials.length - 1];
  if (!lastInitial) {
    lastInitial = "";
  }

  if (initials.length == 1) {
    lastInitial = "";
  }

  const avatarInitials = firstInitial + lastInitial;

  return (
    <>
      <Avatar
        onClick={(e) => {
          setMenuAnchorElement(e.currentTarget);
        }}
        sx={{
          background: `linear-gradient(261.93deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          color: "white",
          fontSize: "16px",
        }}
      >
        {loading ? "" : avatarInitials}
      </Avatar>
      <Menu
        id="menu-appbar"
        anchorEl={menuAnchorElement}
        keepMounted
        open={Boolean(menuAnchorElement)}
        onClose={() => setMenuAnchorElement(null)}
        PaperProps={{
          sx: {
            width: "200px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            nav("/profile");
            setMenuAnchorElement(null);
          }}
        >
          Profile
        </MenuItem>

        <MenuItem
          onClick={() => {
            jwtContext.setThemeMode(
              jwtContext.themeMode === "dark" ? "light" : "dark"
            );
            setMenuAnchorElement(null);
          }}
        >
          {jwtContext.themeMode === "dark" ? "Light Mode" : "Dark Mode"}
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => {
            jwtContext.setJwt(undefined);
            setMenuAnchorElement(null);
            nav("/");
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export const GlowAppBar: React.FC = () => {
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);
  const jwtContext = useContext(GlowJwtContext);

  const loggedIn = jwtContext.jwt?.principalType === PrincipalType.Verified;

  const nav = useNavigate();
  const theme = useTheme();

  const [activeQueryCount, setActiveQueryCount] = useState(0);
  const [showLoadingBar, setShowLoadingBar] = useState(false);
  const debugAnimation = false;

  const [hideAuthButton, setHideAuthButton] = useState(false);

  useEffect(() => {
    const handleAuthActive = (active: boolean) => {
      setHideAuthButton(active);
    };
    GlowEventBus.addAuthButtonListener(handleAuthActive);

    return () => {
      GlowEventBus.removeAuthButtonListener(handleAuthActive);
    };
  });

  useEffect(() => {
    const handleCount = (count: number) => {
      setActiveQueryCount(count);
      if (count > 0) {
        setShowLoadingBar(true);
      }
    };
    GlowEventBus.addQueryCountListener(handleCount);

    return () => {
      GlowEventBus.removeQueryCountListener(handleCount);
    };
  });

  useEffect(() => {
    if (activeQueryCount == 0) {
      const timeout = setTimeout(() => {
        setShowLoadingBar(false);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [new Date(), activeQueryCount]);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          position: "fixed important!",
          top: "0px",
          height: "56px",
        }}
      >

        <GlowSignUpOrLoginDialogX
          open={signUpOpen}
          defaultMode="sign-up"
          onClose={() => setSignUpOpen(false)}
          onLoginComplete={() => setSignUpOpen(false)}
        />
        <GlowSignUpOrLoginDialogX
          open={signInOpen}
          defaultMode="login"
          onClose={() => setSignInOpen(false)}
          onLoginComplete={() => setSignInOpen(false)}
        />

        <Grid
          container
          sx={{
            padding: "4px 16px",
          }}
        >
          <Grid item xs={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Button
                onClick={() => {
                  nav("/");
                }}
                sx={{
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <Logo height="appbar" forceDark />
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
              }}
            >
              {!loggedIn && !hideAuthButton && (
                <>
                  {!jwtContext.returningUser && (
                    <Button
                      sx={{
                        color: "white",
                      }}
                      variant={jwtContext.returningUser ? "contained" : "text"}
                      onClick={() => {
                        setSignInOpen(true);
                      }}
                    >
                      Sign In
                    </Button>
                  )}
                  <Button
                    sx={{
                      color: "white",
                    }}
                    variant={jwtContext.returningUser ? "text" : "contained"}
                    onClick={() => {
                      setSignUpOpen(true);
                    }}
                  >
                    Sign Up
                  </Button>
                  {jwtContext.returningUser && (
                    <Button
                      sx={{
                        color: "white",
                      }}
                      variant={jwtContext.returningUser ? "contained" : "text"}
                      onClick={() => {
                        setSignInOpen(true);
                      }}
                    >
                      Sign In
                    </Button>
                  )}
                </>
              )}
              {loggedIn && (
                <>

                  <Link
                    onClick={() => nav("/events")}
                    sx={{
                      color: "white",
                    }}
                  >
                    Events
                  </Link>
                  <Link
                    onClick={() => nav("/polls")}
                    sx={{
                      color: "white",
                    }}
                  >
                    Polls
                  </Link>
                  <ProfileButton />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "fixed",
            top: "56px",
            width: "100%",
            height: showLoadingBar ? "3px" : "0px",
            // height: "20px",
            // background: `linear-gradient(90deg, ${theme.palette.secondary.main}, 48%, ${theme.palette.background.default}, 52%, ${theme.palette.secondary.main})`,
            background: `linear-gradient(90deg, ${theme.palette.background.paper}, 40%, ${theme.palette.secondary.light}, 60%, ${theme.palette.background.paper})`,
            backgroundColor: "pink",
            backgroundSize: "200% auto",
            animation: showLoadingBar
              ? "gradientAnimation 1s linear infinite"
              : "none",
            "@keyframes gradientAnimation": {
              "0%": {
                backgroundPosition: "100% 0%",
              },
              "50%": {
                backgroundPosition: "0% 0%",
              },
              "100%": {
                backgroundPosition: "100% 0%",
              },
            },
          }}
        />
      </AppBar>
    </>
  );
};
