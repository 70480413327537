import { ThemeOptions, createTheme } from "@mui/material";
import { GlowDarkTheme } from "./GlowDarkTheme";

const theme = createTheme(GlowDarkTheme)

const GlowLightTheme: ThemeOptions = {
    ...GlowDarkTheme,
    palette: {
        ...GlowDarkTheme.palette,
        mode: "light",
        primary: {
            main: "#049570",
        }
    },
    components: {
        ...GlowDarkTheme.components,
        MuiAppBar:{
            styleOverrides:{
                root:{
                    // background: "white",
                    background:
                    "linear-gradient(209.26deg, #1D308B -0.41%, #110036 100%)",
                }
            }
        },
        MuiButton:{
            ...GlowDarkTheme.components?.MuiButton,
            variants:[
                ...GlowDarkTheme.components!.MuiButton!.variants!,
                {
                    props: { variant: 'text' },
                    style:{
                       color: theme.palette.primary.dark
                    }
                }
            ]
        },
        MuiTooltip:{
            styleOverrides:{
                tooltip:{
                    color: "rgba(0, 0, 0, 0.87)",
                    background: "#FFFFFF",
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                },
                arrow:{
                    color: "#FFFFFF",

                }
            }
        }
    }
}


export {GlowLightTheme}