import {
  Box,
  Button,
  Dialog,
  FormGroup,
  Link,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { GlowTextField } from "../components/GlowTextField";
import { GlowPhoneField } from "../components/GlowPhoneField";
import React, { useContext, useRef, useState } from "react";
import { GlowFormBox } from "../components/GlowFormBox";
import { GlowAuthCodeEntry } from "../components/GlowAuthCodeEntry";
import { useMutation } from "@apollo/client";
import { gql } from "../../codegen";
import { GlowJwtContext } from "../../jwt/GlowJWTContext";
import { GlowColumnBox } from "../components/GlowBox";

export const START_LOGIN_V3 = gql(`
  mutation startLoginV3($name: String!, $sms: String!, $returningUser:Boolean!)
  {
    startLogin(name:$name, smsNumber:$sms, returningUser:$returningUser)
    {
      token,
      principalType
    }
  }
`);

export const COMPLETE_LOGIN_V3 = gql(`
mutation completeLoginV3($confirmCode: String!)
{
  completeLogin(confirmCode:$confirmCode)
    {
        token,
        principalType
    }
}
`);

export const RESEND_CODE_V3 = gql(`
mutation resendCode
{
	resendCode
	{
		token
		principalType
	}
}`);

export interface GlowSignUpOrLoginDialogProps {
  open: boolean;
  defaultMode: "sign-up" | "login";
  onClose: () => void;
  onLoginComplete: () => void;
}

interface SignUpViewProps {
  goToSignIn: () => void;
  setToken: (token: string) => void;
  codeRequested: () => void;
  inputFocusRef: React.RefObject<HTMLInputElement>;
  phoneValue: string;
  setPhoneValue: (phone: string) => void;
  failedLogin: boolean;
}

const SignUpView: React.FC<SignUpViewProps> = (props) => {
  const [name, setName] = useState("");
  const [startChallenge, startChallengeState] = useMutation(START_LOGIN_V3);

  return (
    <GlowFormBox
      inDialog
      onSubmit={(e) => {
        e.preventDefault();

        startChallenge({
          // Override the auth credential.
          context: {
            headers: {},
          },
          variables: {
            name: name,
            sms: props.phoneValue.replaceAll(" ", ""),
            returningUser: false,
          },
          onCompleted: (data) => {
            props.setToken(data.startLogin.token);
          },
        });
        props.codeRequested();
      }}
    >
      <Typography variant="h6">
        {!props.failedLogin ? "Sign up" : "We need your name for login"}
      </Typography>
      {props.failedLogin ? (
        <></>
      ) : (
        <Typography variant="body1">
          We will text you a code to verify
        </Typography>
      )}
      <FormGroup sx={{ gap: 2 }}>
        <GlowTextField
          label="Name"
          required
          autoFocus
          disabled={startChallengeState.loading}
          autoComplete="name"
          value={name}
          onBlur={() => {
          }}
          onChange={(x) => {
            setName(x.target.value);
          }}
        />
        <GlowPhoneField
          label="Mobile"
          required
          disabled={startChallengeState.loading}
          value={props.phoneValue}
          onChange={(x) => props.setPhoneValue(x)}
        />
      </FormGroup>
      <Button
        variant="contained"
        type="submit"
        onClick={() => {
          props.inputFocusRef.current!.focus();
        }}
      >
        Verify Number
      </Button>
      <Typography variant="caption">
        Already have an account?{" "}
        <Link
          onClick={() => {
            props.goToSignIn();
          }}
        >
          Sign in
        </Link>
      </Typography>
    </GlowFormBox>
  );
};

interface LoginViewProps {
  goToSignUp: () => void;
  goToSignUpAndRequireName: () => void;
  setToken: (token: string) => void;
  codeRequested: () => void;
  inputFocusRef: React.RefObject<HTMLInputElement>;
  phoneValue: string;
  setPhoneValue: (phone: string) => void;
}
const LoginView: React.FC<LoginViewProps> = (props) => {
  const [startChallenge, startChallengeState] = useMutation(START_LOGIN_V3);

  return (
    <GlowFormBox
      inDialog
      onSubmit={(e) => {
        e.preventDefault();

        startChallenge({
          // Override the auth credential.
          context: {
            headers: {},
          },
          variables: {
            name: "",
            sms: props.phoneValue.replaceAll(" ", ""),
            returningUser: true,
          },
          onCompleted: (data) => {
            props.setToken(data.startLogin.token);
            props.codeRequested();
          },
          onError: (err) => {
            if (err.message === "Must provide name for profile") {
              props.goToSignUpAndRequireName();
            }
          },
        });
      }}
      sx={{
        padding: "16px",
      }}
    >
      <Typography variant="h6">Welcome back</Typography>
      <Typography variant="body1">
        Enter your phone number and we will text you a code to access your
        account.
      </Typography>
      <FormGroup sx={{ gap: 2 }}>
        <GlowPhoneField
          autoFocus
          label="Mobile"
          required
          value={props.phoneValue}
          onChange={(x) => props.setPhoneValue(x)}
        />
      </FormGroup>
      <Button
        variant="contained"
        type="submit"
        onClick={() => {
          props.inputFocusRef.current!.focus();
        }}
      >
        Login
      </Button>
      <Typography variant="caption">
        Don't have an account?{" "}
        <Link
          onClick={() => {
            props.goToSignUp();
          }}
        >
          Sign up
        </Link>
      </Typography>
    </GlowFormBox>
  );
};

interface VerifyViewProps {
  code: string;
  smsNumber: String;
  inputFocusRef: React.RefObject<HTMLInputElement>;
  error: boolean;
  loading: boolean;
  back: () => void;
  resendSeconds: number;
  resendCode: () => void;
}

export const VerifyView: React.FC<VerifyViewProps> = (props) => {
  return (
    <GlowFormBox inDialog>
      <Typography variant="h6">We sent you a code</Typography>
      <Typography variant="body1">
        Enter the 6-digit code we sent to:
      </Typography>
      <Typography variant="body1">{props.smsNumber}</Typography>
      <GlowAuthCodeEntry
        error={props.error}
        code={props.code}
        inputFocusRef={props.inputFocusRef}
      />
      {/* <Button variant="contained">Next</Button> */}
      {props.resendSeconds > 0 && (
        <Typography variant="caption" sx={{ width: "100%" }}>
          Resend code in {props.resendSeconds}
        </Typography>
      )}
      {props.resendSeconds <= 0 && (
        <Typography variant="caption" sx={{ width: "100%" }}>
          <Link onClick={props.resendCode}>Resend Code</Link>
        </Typography>
      )}
      <Typography variant="caption">
        <Link onClick={() => props.back()}>Back</Link>
      </Typography>
    </GlowFormBox>
  );
};

const DialogContents: React.FC<GlowSignUpOrLoginDialogProps> = (props) => {
  const [mode, setMode] = useState<"sign-up" | "login">(props.defaultMode);
  const [failedLogin, setFailedLogin] = useState(false);
  const [awaitingCode, setAwaitingCode] = useState(false);
  const [phone, setPhone] = useState("");
  const [tempToken, setTempToken] = useState("");
  const tokenInputFocusRef = useRef<HTMLInputElement>(null);
  const [tokenInputVal, setTokenInputVal] = useState("");

  const [completeChallenge, completeChallengeState] =
    useMutation(COMPLETE_LOGIN_V3);

  const [resendCode, resendCodeState] = useMutation(RESEND_CODE_V3);

  const jwtContext = useContext(GlowJwtContext);
  const setAuthCodeAndAutoSubmit = (code: string) => {
    // max token length is 6.
    if (code.length > 6) {
      return;
    }

    setTokenInputVal(code);

    // If the code is 6 digits, submit it.
    if (code.length === 6) {
      completeChallenge({
        // Override the auth credential.
        context: {
          headers: {
            authorization: "Bearer " + tempToken,
          },
        },
        variables: {
          confirmCode: code,
        },

        onError: (x) => {
          setTokenInputVal("");
        },
        onCompleted: (x) => {
          jwtContext.setJwt(x.completeLogin);
          props.onLoginComplete();
        },
      });
    }
  };

  var displayComponent: "sign-up" | "login" | "verify" = "sign-up";

  const [resendSeconds, setResendSeconds] = useState(30);
  const interVal = useRef<NodeJS.Timer | null>(null);

  const handleCodeRequested = () => {
    setAwaitingCode(true);
    setResendSeconds(30);
    if (interVal.current) {
      clearInterval(interVal.current);
    }
    interVal.current = setInterval(() => {
      setResendSeconds((x) => x - 1);
      if (resendSeconds == 0) {
        interVal.current && clearInterval(interVal.current);
      }
    }, 1000);
    tokenInputFocusRef.current?.focus();
  };

  if (mode == "sign-up") {
    displayComponent = "sign-up";
  } else if (mode == "login") {
    displayComponent = "login";
  }
  if (awaitingCode) {
    displayComponent = "verify";
  }

  return (
    <>
      <Slide in={displayComponent === "sign-up"} direction="right">
        <Box
          sx={{
            position: "absolute",
          }}
        >
          <SignUpView
            goToSignIn={() => {
              setMode("login");
            }}
            failedLogin={failedLogin}
            inputFocusRef={tokenInputFocusRef}
            setToken={(x) => setTempToken(x)}
            codeRequested={handleCodeRequested}
            phoneValue={phone}
            setPhoneValue={(x) => setPhone(x)}
          />
        </Box>
      </Slide>

      <Slide in={displayComponent === "login"} direction="right">
        <Box
          sx={{
            position: "absolute",
          }}
        >
          <LoginView
            goToSignUp={() => {
              setMode("sign-up");
              setFailedLogin(false);
            }}
            goToSignUpAndRequireName={() => {
              setMode("sign-up");
              setFailedLogin(true);
            }}
            phoneValue={phone}
            setPhoneValue={(x) => setPhone(x)}
            inputFocusRef={tokenInputFocusRef}
            setToken={(x) => setTempToken(x)}
            codeRequested={handleCodeRequested}
          />
        </Box>
      </Slide>

      <Slide in={displayComponent === "verify"} direction="left">
        <Box
          sx={{
            position: "absolute",
          }}
        >
          <VerifyView
            code={tokenInputVal}
            smsNumber={phone}
            inputFocusRef={tokenInputFocusRef}
            error={completeChallengeState.error !== undefined}
            loading={completeChallengeState.loading}
            back={() => setAwaitingCode(false)}
            resendSeconds={resendSeconds}
            resendCode={() => {
              resendCode({
                context: {
                  headers: {
                    authorization: "Bearer " + tempToken,
                  },
                },
                onCompleted: (x) => {
                  setTempToken(x.resendCode.token);
                  handleCodeRequested();
                },
              });
            }}
          />
        </Box>
      </Slide>

      <TextField
        inputRef={tokenInputFocusRef}
        value={tokenInputVal}
        type="number"
        inputMode="numeric"
        autoComplete="one-time-code"
        onChange={(x) => setAuthCodeAndAutoSubmit(x.target.value)}
        // disabled={props.disabled}
        inputProps={{ pattern: "[0-9]*" }}
        sx={{
          position: "absolute",
          top: -4999,
          left: -4999,
        }}
      />
    </>
  );
};

export const GlowSignUpOrLoginDialogX: React.FC<
  GlowSignUpOrLoginDialogProps
> = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.onClose()}
        PaperProps={{
          sx: {
            height: "400px",
            width: "350px",
            margin: "0px",
            overflow: "clip",
            position: {
              xs: "fixed",
              sm: undefined,
            },
            top: {
              xs: "66px",
              sm: undefined,
            },
          },
        }}
      >
        <GlowColumnBox
          sx={{
            height: "400px",
            width: "350px",
            margin: "0px",
            justifyContent: "center",
          }}
        >
          <DialogContents
            {...props}
            onLoginComplete={() => {
              props.onLoginComplete();
            }}
          />
        </GlowColumnBox>
      </Dialog>
    </>
  );
};
