import { ReservationStatus } from "../codegen/graphql";

export const GetTextForStatus = function (status: ReservationStatus): string
{
    switch(status)
    {
        case ReservationStatus.Yes: 
            return "Yes";
        case ReservationStatus.No:
            return "No";
        case ReservationStatus.Maybe:
            return "Maybe";
        case ReservationStatus.Waitlist:
            return "Waitlist";
        default:
            return "Unknown";
    }
}