import { Button, Dialog, Typography } from "@mui/material";
import { GlowColumnBox } from "./GlowBox";
import { GlowSecondaryPaper } from "./GlowSecondaryPaper";
import { Moment } from "moment";
import { useSanityLocalStrings } from "../../cms/queries/getLocalizedStrings";

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
  link?: string;
  textToUse: string;
  title: string;
}

export const ShareDialog: React.FC<ShareDialogProps> = (props) => {
  const lines = props.textToUse.split("\n");
  const messageLink = `sms:/&body=${encodeURI([...lines].join("\n"))}`;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          margin: "16px",
        },
      }}
    >
      <GlowColumnBox
        sx={{
          padding: "12px",
          margin: "0px",
        }}
      >
        <Typography variant="h5">{props.title}</Typography>
        <GlowSecondaryPaper>
          <GlowColumnBox
            sx={{
              alignItems: "left",
              gap: "0px",
            }}
          >
            {lines.map((line, ndx) => (
              <Typography key={ndx}>{line}&nbsp;</Typography>
            ))}
          </GlowColumnBox>
        </GlowSecondaryPaper>
        <Button href={messageLink} variant="contained">
          Open in Message
        </Button>
        <GlowColumnBox
          sx={{
            margin: "0px",
            padding: "0px",
            gap: "0px",
          }}
        >
          <Button
            variant="text"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText([...lines].join("\n"));
            }}
          >
            Copy Message
          </Button>
          {props.link && (
            <Button
              variant="text"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(props.link!);
              }}
            >
              Copy Link
            </Button>
          )}
        </GlowColumnBox>
      </GlowColumnBox>
    </Dialog>
  );
};

interface ShareEventDialogProps {
  open: boolean;
  locationName: string;
  isoDate: Moment;
  eventTitle: string;
  eventCode: string;
  onClose: () => void;
}

export const ShareEventDialog: React.FC<ShareEventDialogProps> = (props) => {
  var host = window.location.host;
  if (host.startsWith("www.")) {
    host = host.substring(4);
  }
  const eventLink = `${window.location.protocol}//${host}/${props.eventCode}`;

  const sanityLocalStrings = useSanityLocalStrings();

  const magicWordMap = {
    "[EVENT_TITLE]": props.eventTitle,
    "[DATE]": props.isoDate.format("dddd, M/D"),
    "[TIME]": props.isoDate.format("h:mma"),
    "[LOCATION]": props.locationName,
    "[EVENT_LINK]": eventLink,
  };

  const containsAllMagicWords = (text: string): boolean => {
    for (const magicWord of Object.keys(magicWordMap)) {
      if (!text.includes(magicWord)) {
        return false;
      }
    }
    return true;
  };

  const text = sanityLocalStrings.strings.eventView_InviteShareText;

  var textToUse = text;
  if (!containsAllMagicWords(textToUse)) {
    textToUse = `Hey! I'm hosting [EVENT_TITLE],
[DATE] at [TIME]
[LOCATION]

🌟 RSVP + easily add to your cal.

[EVENT_LINK].`;
  }

  for (const magicWord of Object.keys(magicWordMap)) {
    textToUse = textToUse.replace(magicWord, (magicWordMap as any)[magicWord]);
  }

  return (
    <ShareDialog
      title="Share Event"
      open={props.open}
      onClose={props.onClose}
      link={eventLink}
      textToUse={textToUse}
    />
  );
};

interface SharePollDialogProps {
  open: boolean;
  onClose: () => void;
  mode: "time" | "location" | "option";
  pollTitle: string;
  pollId: string;
  reason: "created" | "updated";
}

export const SharePollDialog: React.FC<SharePollDialogProps> = (props) => {
  var host = window.location.host;
  if (host.startsWith("www.")) {
    host = host.substring(4);
  }
  const link = `${window.location.protocol}//${host}/p/${props.pollId}`;

  const newTextToUse = `Hey! I'm trying to find a good ${props.mode} for ${props.pollTitle}.  Please fill out this quick poll.

🌟 Poll via Glow 🌟

${link}`;

const reshareTextToUse = `Hey! I've updated my poll ${props.pollTitle}. Can you double check your responses?

🌟 Poll via Glow 🌟

${link}`;

  const title = props.reason === "created" ? "Share Poll" : "Reshare your poll";
  const textToUse = props.reason === "created" ? newTextToUse : reshareTextToUse;
  

  return (
    <ShareDialog
      title= {title}
      open={props.open}
      onClose={props.onClose}
      link={link}
      textToUse={textToUse}
    />
  );
};
