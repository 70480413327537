import { ConfirmationDialog } from "./ConfirmationDialog";

interface PlusOneWaitlistDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const PlusOneWaitlistDialog: React.FC<PlusOneWaitlistDialogProps> = (
  props
) => {
  return <ConfirmationDialog
    open={props.open}
    onCancel={props.onCancel}
    onConfirm={props.onConfirm}
    bias="cancel"
    header="Event is full!"
    message="There are no spots left, adding a plus-1 would mean putting you on the waitlist."

    okText="Move to waitlist"
    cancelText="Cancel"
   />;
};
