import { gql } from "../../codegen_cms";
import { useSanityQuery } from "../useSanityQuery";

export const GET_POSTS = gql(`
query AllPosts {
  allPost {
    title
    slug {
      source
      current
    }
    published
    mainImage
    {
      asset
      {
        url
      }
    }
    shortForm
    body
  }
}`);

export const useAllPosts = () => {
  return useSanityQuery(GET_POSTS, {
    allPost: [],
  });
};
