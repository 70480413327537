import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";
import { AppV2 } from "./AppV2";
import LogRocket from "logrocket";
import moment from "moment-timezone";

if (
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("dev")
) {
} else {
  LogRocket.init("gc7uwa/glow");
}

// Setup Moment Timezones
var abbrs = {
  EST: "Eastern Standard Time",
  EDT: "Eastern Daylight Time",
  CST: "Central Standard Time",
  CDT: "Central Daylight Time",
  MST: "Mountain Standard Time",
  MDT: "Mountain Daylight Time",
  PST: "Pacific Standard Time",
  PDT: "Pacific Daylight Time",
};

moment.fn.zoneName = function () {
  var abbr = this.zoneAbbr();

  if (abbr in abbrs) {
    return Object(abbrs)[abbr];
  }
  return abbr;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <AppInsightsContext.Provider value={reactPlugin}>
      <React.StrictMode>
        <AppV2 />
      </React.StrictMode>
    </AppInsightsContext.Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
