import { Theme, useMediaQuery } from "@mui/material";

export const ThemeExtensions = (theme: Theme) => {

  return {
    text: {
      accent:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.dark,
    },
    palette: {
      extraPaperColor: `${theme.palette.secondary.main}2D`,
    },
  };
};
