import { ThemeOptions } from "@mui/material";

export const GlowDarkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#06D6A0",
    },
    secondary: {
      main: "#42a1f5",
    },
  },
  typography: {
    fontFamily: "Comfortaa",
    body1: {
      fontFamily: "Josefin Sans",
    },
    body2: {
      fontFamily: "Josefin Sans",
    },
    h6: {
      fontFamily: "Josefin Sans",
    },
    caption: {
      fontFamily: "Josefin Sans",
    },
    button: {
      fontFamily: "Josefin Sans",
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: "Josefin Sans",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: "white",
          background:
            "linear-gradient(29.22deg, #1a598a 22.08%, rgba(5, 187, 187, 1) 121.28%)",
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          sx: {
            minWidth: "200px",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            borderRadius: 100,
            background:
              "linear-gradient(29.22deg, #02866E 22.08%, rgba(5, 207, 171, 1) 121.28%)",
            color: "white",
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            borderRadius: 100,
            background:
              "linear-gradient(29.22deg, #1a598a 22.08%, rgba(5, 187, 187, 1) 121.28%)",
            color: "white",
          },
        },
        {
          props: { variant: "contained", disabled: true },
          style: {
            borderRadius: 100,
            background: "gray",
            color: "white",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 100,
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
          },
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                //   backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                backgroundColor: "#42a1f5",
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: "#f5f5f5",
              //   theme.palette.mode === 'light'
              //     ? theme.palette.grey[100]
              //     : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              // opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
              opacity: 0.3,
            },
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            // backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            backgroundColor: "#b9b9b9",
            opacity: 1,
          }
        },
      },
    },
  },
};
