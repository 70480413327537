import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const devAiStr =
  "InstrumentationKey=977cbc44-b08d-45a3-9f42-e228fcc04c6e;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/";
const prodAiStr =
  "InstrumentationKey=d773bac6-7804-4470-9f67-2e2104f8ea40;IngestionEndpoint=https://southcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/";
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
   // connectionString: devAiStr,
     connectionString: prodAiStr,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

appInsights.addTelemetryInitializer((envelope) => {
  if (envelope?.tags) {
    envelope.tags["ai.cloud.role"] = "GlowWeb";
  }
});

export { reactPlugin, appInsights, withAITracking };
