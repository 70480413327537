import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import moment from "moment";
import { decodeJwt } from "jose";
import { setContext } from "@apollo/client/link/context";
import { appInsights } from "./appInsights";
import { Jwt, PrincipalType } from "./codegen/graphql";
import { ReactNode, useContext } from "react";
import { GlowJwtContext } from "./jwt/GlowJWTContext";
import { RetryLink } from "@apollo/client/link/retry";
import { GlowEventBus } from "./messaging/eventBus";

const isTokenExpired = function (token: string): boolean {
  try {
    const decodedToken = decodeJwt(token);

    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const tokenExpiry = moment.unix(decodedToken.exp);
    const fiveMinutesAgo = moment().subtract(5, "minutes");

    return tokenExpiry.isBefore(fiveMinutesAgo);
  } catch (error) {
    console.log(JSON.stringify(error));
    return true;
  }
};

const everLoggedInKey = "glow.ever.logged.in.v20230503";
const jwtTokenKey = "glow.jwt.token.v202302328";
const jwtTypeKey = "glow.jwt.type.v202302328";

export const hasUserEverLoggedIn = function (): boolean {
  return localStorage.getItem(everLoggedInKey) !== null;
};

const clearGlowJWT = function () {
  console.log("clearing token");
  localStorage.removeItem(jwtTokenKey);
  localStorage.removeItem(jwtTypeKey);
  glowApolloClient.clearStore();
};
const setGlowJWT = function (jwt: Jwt) {
  localStorage.setItem(jwtTokenKey, jwt.token);
  localStorage.setItem(jwtTypeKey, jwt.principalType);

  if (jwt.principalType == PrincipalType.Verified) {
    localStorage.setItem(everLoggedInKey, new Date().toISOString());
  }

  glowApolloClient.clearStore();
};

const getGlowJWT = function (): Jwt | null {
  var jwtToken = localStorage.getItem(jwtTokenKey);
  var jwtType = localStorage.getItem(jwtTypeKey);

  if (jwtToken && isTokenExpired(jwtToken)) {
    clearGlowJWT();
    return null;
  }

  if (jwtToken && jwtType) {
    return {
      token: jwtToken,
      principalType: jwtType as any,
    };
  }
  return null;
};

const httpLink = createHttpLink({
  // uri: "http://localhost:7071/api/graphql",
  // uri: "https://glw-bfp9-api-fa.azurewebsites.net/api/graphql",
  uri: "https://glw-prod-api-fa.azurewebsites.net/api/graphql",
});

const jwtLink = setContext((req, ctxt) => {
  // get the authentication token from local storage if it exists
  const token = getGlowJWT();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token.token}` : "",
      ...ctxt.headers,
    },
  };
});

const appInsightsLink = new ApolloLink((op, fwd) => {
  const eventName = "GraphQL: " + op.operationName;
  var user = "?";

  try {
    const jwt = getGlowJWT();
    if (jwt) {
      user = JSON.stringify(decodeJwt(jwt.token));
    }
  } catch {}

  appInsights.trackEvent(
    {
      name: eventName,
    },
    {
      query: op.query?.loc?.source.body.trim() ?? "",
      vars: JSON.stringify(op.variables),
      user: user,
    }
  );
  return fwd(op);
});

const glowApolloClient = new ApolloClient({
  link: appInsightsLink.concat(jwtLink.concat(httpLink)),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

interface GlowApolloProviderProps {
  children: ReactNode;
}

export const GlowApolloProvider: React.FC<GlowApolloProviderProps> = (
  props
) => {
  const jwt = useContext(GlowJwtContext);

  const networkCountLInk = new ApolloLink((op, fwd) => {
    GlowEventBus.incrementActiveQueryCount();

    const timeout = setTimeout(() => {
      GlowEventBus.decrementActiveQueryCount();
    }, 5000);

    try {
      return fwd(op).map((data) => {
        GlowEventBus.decrementActiveQueryCount();
        return data;
      });
    } finally {
      GlowEventBus.decrementActiveQueryCount();
    }
  });

  const contextJwtLink = setContext((req, ctxt) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        authorization: jwt.jwt ? `Bearer ${jwt.jwt.token}` : "",
        ...ctxt.headers,
      },
    };
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 1000,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error,
    },
  });

  const client = new ApolloClient({
    link: appInsightsLink.concat(
      retryLink.concat(contextJwtLink.concat(networkCountLInk.concat(httpLink)))
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export { glowApolloClient, setGlowJWT, getGlowJWT, clearGlowJWT };
