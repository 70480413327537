import { Moment } from "moment";
import { GlowFormBox } from "../components/GlowFormBox";
import { GlowTextField } from "../components/GlowTextField";
import { GlowDateTimePicker } from "../components/GlowDateTimePicker";
import { GlowLocationEntry } from "../components/GlowLocationEntry";
import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { gql } from "../../codegen";
import { useMutation } from "@apollo/client";
import { GlowSecondaryPaper } from "../components/GlowSecondaryPaper";
import { GlowColumnBox } from "../components/GlowBox";
import { GlowEventPatch } from "../../codegen/graphql";

interface EventPatchFormProps {
  eventCode: string;
  initialName: string;
  initialDateTime: Moment;
  initialLocationName: string;
  initialLocationMapUrl?: string;
  initialLocationAddress?: string;
  initialDescription?: string;
  initialTimeZone: string;
  onSaved?: () => void;
}

const PATCH_EVENT_MUTATION = gql(`
mutation patchEventV3($eventCode:String!, $patch:GlowEventPatch!)
{
	patchEvent(eventCode: $eventCode, patch: $patch)
	{
		eventCode,
	}
}`);

export const EventPatchForm = (props: EventPatchFormProps) => {
  const [name, setName] = useState(props.initialName);
  const [dateTime, setDateTime] = useState(props.initialDateTime);
  const [locationName, setLocationName] = useState(props.initialLocationName);
  const [locationMapUrl, setLocationMapUrl] = useState(
    props.initialLocationMapUrl ?? ""
  );
  const [locationAddress, setLocationAddress] = useState(
    props.initialLocationAddress ?? ""
  );
  const [description, setDescription] = useState(
    props.initialDescription ?? ""
  );

  const [timeZone, setTimeZone] = useState(props.initialTimeZone);

  const [patchEvent, patchEventState] = useMutation(PATCH_EVENT_MUTATION);

  return (
    <GlowFormBox
      onSubmit={(e) => {
        e.preventDefault();
        patchEvent({
          variables: {
            eventCode: props.eventCode,
            patch: {
              eventTitle: name,
              startTime: dateTime.toISOString(),
              location: locationName,
              locationMapUrl: locationMapUrl,
              locationAddress: locationAddress,
              description: description,
              timeZone: timeZone,
            },
          },
          onCompleted: () => {
            if (props.onSaved) {
              props.onSaved();
            }
          },
        });
      }}
    >
      <Typography variant="h6">Edit your event</Typography>
      <GlowTextField
        label="Event Name"
        required
        value={name}
        onChange={(x) => setName(x.target.value)}
      />
      <GlowDateTimePicker
        allowPast
        time={dateTime}
        timeZone={timeZone}
        onChange={(x, y) => {
          setDateTime(x);
          setTimeZone(y);
        }}
      />
      <GlowLocationEntry
        required
        locationName={locationName}
        setLocationName={setLocationName}
        locationMapUrl={locationMapUrl}
        setLocationMapUrl={setLocationMapUrl}
        locationAddress={locationAddress}
        setLocationAddress={setLocationAddress}
      />
      <GlowTextField
        multiline
        label="Additional Details"
        value={description}
        onChange={(x) => setDescription(x.target.value)}
      />
      <Button disabled={patchEventState.loading} type="submit">
        Save
      </Button>
    </GlowFormBox>
  );
};

export const EventPatchBox = (props: EventPatchFormProps) => {
  const [name, setName] = useState(props.initialName);
  const [dateTime, setDateTime] = useState(props.initialDateTime);
  const [locationName, setLocationName] = useState(props.initialLocationName);
  const [locationMapUrl, setLocationMapUrl] = useState(
    props.initialLocationMapUrl ?? ""
  );
  const [locationAddress, setLocationAddress] = useState(
    props.initialLocationAddress ?? ""
  );
  const [description, setDescription] = useState(
    props.initialDescription ?? ""
  );

  const [timeZone, setTimeZone] = useState(props.initialTimeZone);

  const [patchEvent, patchEventState] = useMutation(PATCH_EVENT_MUTATION);

  const patchEventNow = (patch: GlowEventPatch) => {
    patchEvent({
      variables: {
        eventCode: props.eventCode,
        patch: patch,
      },
      onCompleted: () => {
        if (props.onSaved) {
          props.onSaved();
        }
      },
    });
  };

  return (
    <GlowSecondaryPaper
      sx={{
        width: "100%",
      }}
    >
      <GlowColumnBox>
        <Typography variant="h6">Edit Event Details</Typography>
        <GlowTextField
          label="Event Name"
          required
          value={name}
          onChange={(x) => setName(x.target.value)}
          onBlur={() => {
            if (name != props.initialName) {
              patchEventNow({ eventTitle: name });
            }
          }}
        />
        <GlowDateTimePicker
          allowPast
          time={dateTime}
          timeZone={timeZone}
          onChange={(x, y) => {
            setDateTime(x);
            setTimeZone(y);
            if (
              x.toISOString() != props.initialDateTime.toISOString() ||
              y != props.initialTimeZone
            ) {
              patchEventNow({
                startTime: x.toISOString(),
                timeZone: y,
              });
            }
          }}
        />
        <GlowLocationEntry
          required
          locationName={locationName}
          setLocationName={setLocationName}
          locationMapUrl={locationMapUrl}
          setLocationMapUrl={setLocationMapUrl}
          locationAddress={locationAddress}
          setLocationAddress={setLocationAddress}
          onBlur={() => {
            patchEventNow({
              location: locationName,
              locationMapUrl: locationMapUrl,
              locationAddress: locationAddress,
            });
          }}
        />
        <GlowTextField
          multiline
          label="Additional Details"
          value={description}
          onChange={(x) => setDescription(x.target.value)}
          onBlur={() => {
            if (description != props.initialDescription) {
              patchEventNow({
                description: description,
              });
            }
          }}
        />
      </GlowColumnBox>
    </GlowSecondaryPaper>
  );
};
