import {
  Autocomplete,
  Button,
  InputAdornment,
  StandardTextFieldProps,
  useTheme,
} from "@mui/material";
import { GlowTextField } from "./GlowTextField";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

interface GlowLocationEntryProps extends StandardTextFieldProps {
  locationName: string;
  setLocationName: (x: string) => void;
  locationMapUrl: string;
  setLocationMapUrl: (x: string) => void;
  locationAddress: string;
  setLocationAddress: (x: string) => void;

  onAddButtonClicked?: () => void;
  onLocationSelected?: (
    locationName: string,
    locationMapUrl: string,
    locationAddress: string
  ) => void;
}

const stripCustomProps = (props: GlowLocationEntryProps) => {
  const {
    locationName,
    setLocationName,
    locationMapUrl,
    setLocationMapUrl,
    ...otherProps
  } = props;
  return otherProps;
};

export const GlowLocationEntry: React.FC<GlowLocationEntryProps> = (
  props: GlowLocationEntryProps
) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyDnXxZLgoUKaReOCVbG9GNu5kQ8J8X193Y",
    debounce: 150,
  });

  const theme = useTheme();

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Autocomplete
        fullWidth
        freeSolo
        filterOptions={(x) => x}
        value={props.locationName}
        options={placePredictions.map((x) => x)}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.description;
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            if (props.onAddButtonClicked && props.locationName.trim().length > 0) {
              props.onAddButtonClicked();
              props.setLocationName("");
              props.setLocationMapUrl("");
              props.setLocationAddress("");
              getPlacePredictions({
                types: [],
                componentRestrictions: { country: "us" },
                input: "",
              });
            }
          }
        }}
        onChange={(event, value, reason) => {
          if (reason === "clear") {
            props.setLocationName("");
            props.setLocationMapUrl("");
            props.setLocationAddress("");
            return;
          }

          if (!value) {
            return;
          }

          if (typeof value === "string") {
            props.setLocationName(value);
            props.setLocationMapUrl("");
            props.setLocationAddress("");
          } else {
            placesService!.getDetails(
              {
                placeId: value.place_id,
              },
              (place) => {
                if (!place) {
                  return;
                }
                props.setLocationName(place.name!);
                props.setLocationMapUrl(place.url!);
                props.setLocationAddress(place.formatted_address!);

                if (props.onLocationSelected) {
                  props.onLocationSelected(
                    place.name!,
                    place.url!,
                    place.formatted_address!
                  );
                  props.setLocationName("");
                  props.setLocationMapUrl("");
                  props.setLocationAddress("");
                }
              }
            );
          }
        }}
        renderInput={(params) => (
          <GlowTextField
            {...params}
            required={props.required}
            label="Location"
            fullWidth
            onFocus={(x) => {
              x.target.select();
            }}
            InputProps={{
              ...params.InputProps,

              startAdornment: props.locationMapUrl ? (
                <InputAdornment
                  position="start"
                  sx={{ paddingLeft: "15px", marginRight: "-20px" }}
                >
                  <LocationOnIcon
                    sx={{ color: theme.palette.secondary.main }}
                  ></LocationOnIcon>
                </InputAdornment>
              ) : undefined,

              endAdornment: props.onAddButtonClicked ? (
                <Button
                  onClick={() => {
                    if (props.locationName.trim().length === 0) {
                      return;
                    }
                    props.onAddButtonClicked!();
                    props.setLocationName("");
                    props.setLocationMapUrl("");
                    props.setLocationAddress("");
                    getPlacePredictions({
                      types: [],
                      componentRestrictions: { country: "us" },
                      input: "",
                    });
                  }}
                  sx={{
                    marginRight:"-15px"
                  }}
                >
                  Add
                </Button>
              ) : undefined,
            }}
            sx={{
              padding: "0px",
            }}
            onBlur={(e) => {
              props.onBlur && props.onBlur(e);
            }}
            onChange={(x) => {
              props.setLocationName(x.target.value);
              props.setLocationMapUrl("");
              getPlacePredictions({
                types: [],
                componentRestrictions: { country: "us" },
                input: x.target.value,
              });
            }}
          />
        )}
      />
      {/* {
        <ul>
          {placePredictions.map((x) => {
            return <li>{x.description}</li>;
          })}
        </ul>
      } */}
    </div>
  );
};
