declare type QueryCountListener = (count: number) => void;
declare type AuthButtonListener = (authActive: boolean) => void;

class EventBus {
  private static _instance: EventBus = new EventBus();

  public static get instance(): EventBus {
    return EventBus._instance;
  }

  private activeQueryCount: number = 0;
  private queryCountListeners: QueryCountListener[] = [];
  private authButtonListeners: AuthButtonListener[] = [];

  private constructor() {}

  public addQueryCountListener(listener: QueryCountListener): void {
    this.queryCountListeners.push(listener);
  }

  public removeQueryCountListener(listener: QueryCountListener): void {
    this.queryCountListeners = this.queryCountListeners.filter(
      (l) => l !== listener
    );
  }

  public addAuthButtonListener(listener: AuthButtonListener): void {
    this.authButtonListeners.push(listener);
  }

  public removeAuthButtonListener(listener: AuthButtonListener): void {
    this.authButtonListeners = this.authButtonListeners.filter(
      (l) => l !== listener
    );
  }

  public setAuthActive(authActive: boolean): void {
    this.authButtonListeners.forEach((l) => l(authActive));
  }

  public incrementActiveQueryCount(): void {
    this.activeQueryCount++;
    this.queryCountListeners.forEach((l) => l(this.activeQueryCount));
  }

  public decrementActiveQueryCount(): void {
    this.activeQueryCount--;
    if (this.activeQueryCount < 0) {
      this.activeQueryCount = 0;
    }
    this.queryCountListeners.forEach((l) => l(this.activeQueryCount));
  }
}

export const GlowEventBus = EventBus.instance;
