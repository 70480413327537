import {
  Box,
  CssBaseline,
  Fab,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GlowFab } from "./GlowFab";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";

export const GlowChrome: React.FC<{
  navigationFAB: boolean;
  animation: boolean;
  shareAction?: () => void;
}> = (props) => {
  const isMobileSize = useMediaQuery("(max-width:767px)");
  const showFab = props.navigationFAB;

  const containerSXMobile: SxProps<Theme> = {
    position: "fixed",
    bottom: (t) => t.spacing(2),
    right: (t) => t.spacing(2),
    zIndex: 100000,
    
  };

  const containerSXDesktop: SxProps<Theme> = {
    position: "fixed",
    width: "100%",
    bottom: (t) => t.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    zIndex: 100000,
  };

  const buttonGroupSXMobile: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    gap: (t) => t.spacing(2),
  };

  const buttonGroupSXDesktop: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    width: "500px",

    gap: (t) => t.spacing(2),
  };

  const containerSX = isMobileSize ? containerSXMobile : containerSXDesktop;
  const buttonGroupSX = isMobileSize ? buttonGroupSXMobile : buttonGroupSXDesktop;

  return (
    <>
      <CssBaseline />

      <Box
        sx={containerSX}
      >
        <Box
          sx={buttonGroupSX}
        >
          {props.shareAction && (
            <Fab
              variant="extended"
              color="primary"
              size="large"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: (t) => t.spacing(2),
                zIndex: 100000,

              }}
              onClick={() => props.shareAction!()}
            >
              <GroupAddRoundedIcon sx={{

              }}/>

              <Typography>Invite Guests</Typography>
            </Fab>
          )}
          {showFab && <GlowFab />}
        </Box>
      </Box>
    </>
  );
};
