import { gql } from "../../codegen_cms";
import { useSanityQuery } from "../useSanityQuery";

export const GET_LOCAL_STRINGS = gql(`
query GetLocalStrings
{
  LocalizableStringSet(id:"3429999e-92b9-4bbf-ac40-b9035ba9cdfc")
  {
    landing_PlanYourNext
    landing_PlanBody1
    landing_PlanBody2
    landing_Section1Header
    landing_Section1Body
    landing_Section2Header
    landing_Section2Body
    landing_Section3Header
    landing_Section3Body
    landing_FooterHeader
    landing_FooterCallToAction
    createEvent_Header
    createEvent_Subheader
    eventView_InviteShareText
  }
}`);

export const useSanityLocalStrings = () => {
  const queryResult = useSanityQuery(GET_LOCAL_STRINGS, {
    LocalizableStringSet: {
      landing_PlanYourNext: "Plan your next",
      landing_PlanBody1:
        "We believe that creating IRL experiences should be easier.",
      landing_PlanBody2:
        "We built Glow so you can spend less time coordinating and more time with the people care about most.",
      landing_Section1Header: "Collect & Track RSVPs",
      landing_Section1Body:
        "Add co-hosts, create waitlists, and create fun themes!",
      landing_Section2Header: "No app download required",
      landing_Section2Body:
        "Organize via text message and never download an app",
      landing_Section3Header: "Create polls in less than 60 seconds",
      landing_Section3Body:
        "Build simple polls to coordinate a time and/or location",
      landing_FooterHeader: "Looking for something to do?",
      landing_FooterCallToAction: "Check out our favorite activities here",
      createEvent_Header: "Create an invite",
      createEvent_Subheader: "Customized event coordination - lightning fast.",
      eventView_InviteShareText: `Hey! I'm hosting [EVENT_TITLE],
      [DATE] at [TIME]
      [LOCATION]
      
      🌟 RSVP + easily add to your cal.
      
      [EVENT_LINK]`
    },
  });

  const sanityStrings = queryResult.data?.LocalizableStringSet;
  const defaultStrings = queryResult.defaultData.LocalizableStringSet!;

  const getField = (fieldName: string): string => {
    return (
      (sanityStrings as any)?.[fieldName] ?? (defaultStrings as any)[fieldName]!
    );
  };

  return {
    loading: queryResult.loading,
    error: queryResult.error,
    strings: {
      landing_PlanYourNext: getField("landing_PlanYourNext"),
      landing_PlanBody1: getField("landing_PlanBody1"),
      landing_PlanBody2: getField("landing_PlanBody2"),
      landing_Section1Header: getField("landing_Section1Header"),
      landing_Section1Body: getField("landing_Section1Body"),
      landing_Section2Header: getField("landing_Section2Header"),
      landing_Section2Body: getField("landing_Section2Body"),
      landing_Section3Header: getField("landing_Section3Header"),
      landing_Section3Body: getField("landing_Section3Body"),
      landing_FooterHeader: getField("landing_FooterHeader"),
      landing_FooterCallToAction: getField("landing_FooterCallToAction"),
      createEvent_Header: getField("createEvent_Header"),
      createEvent_Subheader: getField("createEvent_Subheader"),
      eventView_InviteShareText: getField("eventView_InviteShareText"),
    },
  };
};
