import { useTheme } from "@mui/material"
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import React from "react";



export const GlowPhoneField: React.FC<MuiTelInputProps> = (props) => {
    const theme = useTheme();
    return (
        <MuiTelInput
            {...props}
            InputLabelProps={{
                ...props.InputLabelProps,
                sx: {
                    ...props.InputLabelProps?.sx,
                    fontStyle: 'italic',
                }
            }}
            disableDropdown
            forceCallingCode
            defaultCountry="US"
            onlyCountries={['US']}
            autoComplete="tel-national"
            hiddenLabel={true}
            placeholder="000 000 0000"
            sx={{
                ...props.sx,
                '& label': {
                    paddingLeft: (theme) => theme.spacing(2),
                    paddingRight: (theme) => theme.spacing(2),
                },
                '& input': {
                    paddingLeft: (theme) => theme.spacing(3.5)
                },
                '& fieldset': {
                    paddingLeft: (theme) => theme.spacing(2.5),
                    borderRadius: '100px',
                },
                "& input:-webkit-autofill": {
                    borderTopRightRadius: '100px',
                    borderBottomRightRadius: '100px',
                    textDecoration: `underline ${theme.palette.primary.main} 2px`,
                    WebkitTextFillColor: theme.palette.text.primary,
                    backgroundClip: "content-box !important",
                    WebkitBoxShadow: `0 0 0px 100px ${theme.palette.background.default} inset !important`,
                },
            }}
        />
    )
}