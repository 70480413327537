import { TypeAnimation } from "react-type-animation";
import { GlowColumnBox } from "./GlowBox";
import {  Typography } from "@mui/material";

export const ErrorBox: React.FC<{
  errorHeader?: string;
  errorMessages: string[];
}> = (props) => {
  return (
    <GlowColumnBox sx={{
        marginTop: t=>t.spacing(5),
        padding: t=>t.spacing(2),
    }}>
      <Typography variant="h1" sx={{ color: (t) => t.palette.primary.main, fontFamily:'Josefin Sans'}} >
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            props.errorHeader ?? "Oops!",
            1000, // wait 1s before replacing "Mice" with "Hamsters"
            "",
            1000,
          ]}
          wrapper="span"
          style={{ display: "inline-block" }}
          repeat={Infinity}
          speed={{ type: "keyStrokeDelayInMs", value: 90 }}
          deletionSpeed={{ type: "keyStrokeDelayInMs", value: 120 }}
        />
      </Typography>

      {props.errorMessages.map((msg, i) => (
        <Typography key={i} variant="h6" textAlign="center" sx={{ color: (t) => t.palette.primary.main }}>
          {msg}
        </Typography>
      ))}
    </GlowColumnBox>
  );
};
