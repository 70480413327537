/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nquery GetLandingPageConfig\n{\n  Landing(id:\"1a3c173d-a6a8-4e42-be18-776127740653\")\n  {\n    activities\n    heroImage\n    {\n      asset\n      {\n        url\n      }\n    }\n    section1Image\n    {\n      asset\n      {\n        url\n      }\n    }\n    section2Image\n    {\n      asset\n      {\n        url\n      }\n    }\n    section3Image\n    {\n      asset\n      {\n        url\n      }\n    }\n\t}\n}": types.GetLandingPageConfigDocument,
    "\nquery GetLocalStrings\n{\n  LocalizableStringSet(id:\"3429999e-92b9-4bbf-ac40-b9035ba9cdfc\")\n  {\n    landing_PlanYourNext\n    landing_PlanBody1\n    landing_PlanBody2\n    landing_Section1Header\n    landing_Section1Body\n    landing_Section2Header\n    landing_Section2Body\n    landing_Section3Header\n    landing_Section3Body\n    landing_FooterHeader\n    landing_FooterCallToAction\n    createEvent_Header\n    createEvent_Subheader\n    eventView_InviteShareText\n  }\n}": types.GetLocalStringsDocument,
    "\nquery AllPosts {\n  allPost {\n    title\n    slug {\n      source\n      current\n    }\n    published\n    mainImage\n    {\n      asset\n      {\n        url\n      }\n    }\n    shortForm\n    body\n  }\n}": types.AllPostsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetLandingPageConfig\n{\n  Landing(id:\"1a3c173d-a6a8-4e42-be18-776127740653\")\n  {\n    activities\n    heroImage\n    {\n      asset\n      {\n        url\n      }\n    }\n    section1Image\n    {\n      asset\n      {\n        url\n      }\n    }\n    section2Image\n    {\n      asset\n      {\n        url\n      }\n    }\n    section3Image\n    {\n      asset\n      {\n        url\n      }\n    }\n\t}\n}"): (typeof documents)["\nquery GetLandingPageConfig\n{\n  Landing(id:\"1a3c173d-a6a8-4e42-be18-776127740653\")\n  {\n    activities\n    heroImage\n    {\n      asset\n      {\n        url\n      }\n    }\n    section1Image\n    {\n      asset\n      {\n        url\n      }\n    }\n    section2Image\n    {\n      asset\n      {\n        url\n      }\n    }\n    section3Image\n    {\n      asset\n      {\n        url\n      }\n    }\n\t}\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetLocalStrings\n{\n  LocalizableStringSet(id:\"3429999e-92b9-4bbf-ac40-b9035ba9cdfc\")\n  {\n    landing_PlanYourNext\n    landing_PlanBody1\n    landing_PlanBody2\n    landing_Section1Header\n    landing_Section1Body\n    landing_Section2Header\n    landing_Section2Body\n    landing_Section3Header\n    landing_Section3Body\n    landing_FooterHeader\n    landing_FooterCallToAction\n    createEvent_Header\n    createEvent_Subheader\n    eventView_InviteShareText\n  }\n}"): (typeof documents)["\nquery GetLocalStrings\n{\n  LocalizableStringSet(id:\"3429999e-92b9-4bbf-ac40-b9035ba9cdfc\")\n  {\n    landing_PlanYourNext\n    landing_PlanBody1\n    landing_PlanBody2\n    landing_Section1Header\n    landing_Section1Body\n    landing_Section2Header\n    landing_Section2Body\n    landing_Section3Header\n    landing_Section3Body\n    landing_FooterHeader\n    landing_FooterCallToAction\n    createEvent_Header\n    createEvent_Subheader\n    eventView_InviteShareText\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery AllPosts {\n  allPost {\n    title\n    slug {\n      source\n      current\n    }\n    published\n    mainImage\n    {\n      asset\n      {\n        url\n      }\n    }\n    shortForm\n    body\n  }\n}"): (typeof documents)["\nquery AllPosts {\n  allPost {\n    title\n    slug {\n      source\n      current\n    }\n    published\n    mainImage\n    {\n      asset\n      {\n        url\n      }\n    }\n    shortForm\n    body\n  }\n}"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;