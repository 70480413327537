import { TextField, TextFieldProps, useTheme } from "@mui/material"



export const GlowTextField: React.FC<TextFieldProps> = (props) => {

    const theme = useTheme();
    const color = theme.palette.primary.dark;
    return <TextField {...props}
    fullWidth
        InputLabelProps={{
            ...props.InputLabelProps,
            required: false, // This is to hide the "*" on required fields.
            sx: {
                ...props.InputLabelProps?.sx,
                fontSize: '15px'
            }
        }}
        label={ props.label ? props.required ? props.label : `${props.label} (optional)`: undefined}
        sx={{
            ...props.sx,
            '& label': {
                paddingLeft: (theme) => theme.spacing(2),
                paddingRight: (theme) => theme.spacing(2),
            },
            '& input': {
                paddingLeft: (theme) => `${theme.spacing(3.5)}!important`,
            },
            '& fieldset': {
                paddingLeft: (theme) => theme.spacing(2.5),

                borderRadius: '28px',
            },
            '& textarea': {
                paddingLeft: (theme) => theme.spacing(2.5),

                borderRadius: '28px',
            },
            "& input:-webkit-autofill": {
                borderRadius: '28px',
                textDecoration: `underline ${theme.palette.primary.main} 2px`,
                boxShadow: `0 0 0px 1000px ${theme.palette.background.default} inset !important`,
                color: theme.palette.text.primary,
                WebkitTextFillColor: theme.palette.text.primary,
                backgroundClip: "content-box !important",
                WebkitBoxShadow: `0 0 0px 100px ${theme.palette.background.default} inset !important`,
            },
        }} />
}