import { IconButton, Tooltip } from "@mui/material";
import { ReactNode } from "react";
import InfoIcon from "@mui/icons-material/Info";

export const InfoComponent: React.FC<{ helpBlob: ReactNode }> = (props) => {
  return (
    <Tooltip title={props.helpBlob} arrow>
      <IconButton size="small"> 
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};
