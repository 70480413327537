import { Chip } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { ThemeExtensions } from "../themes/ThemeExtensions";

export const InfoBox: React.FC<{
  message: string;
}> = (props) => {
  return (
    <Chip
      icon={
        <InfoRoundedIcon 
          sx={{
            color: t=> `${t.palette.secondary.light} !important`,
          }}
        />
      }
      label={props.message}
      sx={{
        backgroundColor: (t) => ThemeExtensions(t).palette.extraPaperColor,
        width: "100%",
      }}
    />
  );
};
