import { useContext, useEffect } from "react";
import { useAllPosts } from "../../cms/queries/getPosts";
import { ErrorBox } from "../components/ErrorBox";
import { GlowChrome } from "../components/GlowChrome";
import { ExpandGlowJwt, GlowJwtContext } from "../../jwt/GlowJWTContext";
import { GlowColumnBox } from "../components/GlowBox";
import { Box, useTheme } from "@mui/material";

export const BlogView: React.FC = () => {
  const authContext = useContext(GlowJwtContext);
  const { data, error, loading } = useAllPosts();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (data && data.allPost.length > 0) {
    var postsToRender = data.allPost;
    postsToRender = postsToRender.filter((post) => post.published === true);

    if (authContext.jwt) {
      const expandedJwt = ExpandGlowJwt(authContext.jwt.token);
      if (expandedJwt.sms?.length ?? 100 < 10) {
        postsToRender = data.allPost;
      }
    }

    return (
      <>
        <GlowChrome navigationFAB={false} animation={false} />

        <GlowColumnBox>
          {data.allPost.map((post, i) => (
            <Box>
              <div>
                <h1>{post.title}</h1>
                <p>{post.shortForm}</p>
              </div>
            </Box>
          ))}
        </GlowColumnBox>
      </>
    );
  }

  return <ErrorBox errorMessages={["No posts found"]} />;
};

const SubstackFeed: React.FC = () => {
  const theme = useTheme()  
  useEffect(() => {
    // Configure the widget settings
    (window as any).SubstackFeedWidget = {
      substackUrl: "blog.glow.rsvp",
      posts: 12,
      colors: {
        primary: theme.palette.text.primary,
        secondary: theme.palette.text.secondary,
        background:"#FFFFFF00"
      }
    };

    // Dynamically load the Substack script
    const script = document.createElement("script");
    script.src = "https://substackapi.com/embeds/feed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      // Cleanup the script to prevent duplicates or memory leaks
      document.body.removeChild(script);
    };
  }, [theme]); // Empty dependency array means this effect runs only once

  return <Box id="substack-feed-embed" sx={{
    marginTop: "5vh",
    width: "95%",
    maxWidth: "800px",
  }} />
};

export const BlogView2: React.FC = () => {
  return <SubstackFeed />;
};
