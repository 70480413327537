import ThumbUpOffAltRoundedIcon from "@mui/icons-material/ThumbUpOffAltRounded";
import ThumbDownAltRoundedIcon from "@mui/icons-material/ThumbDownAltRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import { Avatar, SvgIcon, Tooltip, Typography, useTheme } from "@mui/material";
import { ThemeExtensions } from "../themes/ThemeExtensions";
import DiamondRoundedIcon from "@mui/icons-material/DiamondRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

interface ResponseIconProps {
  primaryColor?: string;
  secondaryColor?: string;
  tooltip?: string;
  size?:{
    fontSize?: number;
    avatarSize?: number;
  }
}

interface ResponseIconBaseProps {
  primaryColor: string;
  backgroundColor: string;
  tooltip: string;
  icon: typeof SvgIcon;
  size?:{
    fontSize?: number;
    avatarSize?: number;
  }
}
const ResponseIconBase: React.FC<ResponseIconBaseProps> = (props) => {
  return (
    <Tooltip title={props.tooltip} enterTouchDelay={0}>
      <Avatar
        sx={{
          width: props.size?.avatarSize ?? "24px",
          height: props.size?.avatarSize ?? "24px",
          backgroundColor: props.backgroundColor,
        }}
      >
        <props.icon
          sx={{
            fontSize: props.size?.fontSize ?? 14,
            color: props.primaryColor,
          }}
        />
      </Avatar>
    </Tooltip>
  );
};

export const GoingIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();
  return (
    <ResponseIconBase
      primaryColor={props.primaryColor ?? `${theme.palette.success.main}`}
      backgroundColor={
        props.secondaryColor ?? `${theme.palette.success.main}55`
      }
      tooltip={props.tooltip ?? "Yes"}
      icon={ThumbUpOffAltRoundedIcon}
      size={props.size}
    />
  );
};

export const UnsureIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();

  return (
    <ResponseIconBase
      primaryColor={props.primaryColor ?? `${theme.palette.warning.main}`}
      backgroundColor={
        props.secondaryColor ?? `${theme.palette.warning.main}55`
      }
      tooltip={props.tooltip ?? "Maybe"}
      icon={QuestionMarkRoundedIcon}
    />
  );
};

export const NotGoingIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();

  return (
    <ResponseIconBase
      primaryColor={props.primaryColor ?? `${theme.palette.error.main}`}
      backgroundColor={props.secondaryColor ?? `${theme.palette.error.main}55`}
      tooltip={props.tooltip ?? "No"}
      icon={ThumbDownAltRoundedIcon}
      size={props.size}
    />
  );
};

export const HostIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();

  return (
    <ResponseIconBase
      primaryColor={theme.palette.secondary.main}
      backgroundColor={ThemeExtensions(theme).palette.extraPaperColor}
      tooltip={props.tooltip ?? "Host"}
      icon={DiamondRoundedIcon}
      size={
        {
          fontSize: 20,
        }
      }
    />
  );
};

export const ConstructionIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();

  return (
    <Avatar
      sx={{
        width: "24px",
        height: "24px",
        backgroundColor: ThemeExtensions(theme).palette.extraPaperColor,
      }}
    >
      {/* <Typography
        sx={{
          fontSize: "14px",
        }}>🌟</Typography> */}

      <ConstructionRoundedIcon
        sx={{
          fontSize: "20px",
          color: theme.palette.secondary.main,
        }}
      />
    </Avatar>
  );
};

export const WaitlistIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();
  return (

    <ResponseIconBase
    primaryColor={ theme.palette.mode=="light"? "#333333": "#aaa"}
    backgroundColor={"#BBBBBB55"}
    tooltip={props.tooltip ?? "Waitlist"}
    icon={PendingActionsRoundedIcon}
    size={
      {
        fontSize: 18,
        ...props.size
      }
    }
    />
  );
};

export const SendIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        width: "24px",
        height: "24px",
        backgroundColor: "BBBBBB55",
      }}
    >
      <PendingActionsRoundedIcon
        sx={{
          fontSize: "14px",
          color: "#333333",
        }}
      />
    </Avatar>
  );
};

export const PreferIcon: React.FC<ResponseIconProps> = (props) => {
  const theme = useTheme();
  return (
    <ResponseIconBase
      primaryColor={"#FFA800"}
      backgroundColor={"#FFE589"}
      tooltip={props.tooltip ?? "Preferred"}
      icon={StarRoundedIcon}
      size={
        {
          fontSize: 20,
          ...props.size
        }
      }
    />
  );
};
