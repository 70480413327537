import { Divider, Fab, Menu, MenuItem, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { GlowJwtContext } from "../../jwt/GlowJWTContext";
import { PrincipalType } from "../../codegen/graphql";
import { useNavigate } from "react-router-dom";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { min } from "moment";

export const GlowFab: React.FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const context = useContext(GlowJwtContext);
  const navigate = useNavigate();

  const navigateAndClose = (path: string) => {
    navigate(path);
    setAnchorEl(null);
  }

  return (
    <>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper:{
            sx:{
              borderRadius: "14px",
              minWidth: "200px",
            }
          }
        }}
      >
        <MenuItem onClick={() => navigateAndClose("/create?mode=poll")}>Create a Poll</MenuItem>
        <Divider />

        <MenuItem onClick={() => navigateAndClose("/create?mode=event")}>Host an Event</MenuItem>

      </Menu>
      {context.jwt?.principalType === PrincipalType.Verified && (
        <Fab
          size="medium"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <AddRoundedIcon fontSize="large" />
        </Fab>
      )}
    </>
  );
};
