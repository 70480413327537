import { Box, Button, ButtonProps } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";


interface GlowEditButtonProps extends ButtonProps
{
    text: string;
}

export const GlowEditButton: React.FC<GlowEditButtonProps> = (props) => {
  return (
    <Button
      variant="text"
      endIcon={<EditRoundedIcon />}
      sx={{
        position: "absolute",
        float: "right",
        margin: "5px",
        right: "10px",
      }}
      {...props}
    >
      <Box sx={{ lineHeight: "0px", marginTop: "5px" }}>{props.text}</Box>
    </Button>
  );
};
