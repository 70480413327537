import { Box, useTheme } from "@mui/material";
import darkLogo from "../logos/green_white.svg";
import lightLogo from "../logos/green.svg";
import outlookLogoDark from "../logos/microsoft-outlook-icon-dark.svg";
import outlookLogoLight from "../logos/microsoft-outlook-icon-light.svg";

interface LogoProps {
  height?: "default" | "appbar" | "large";
  forceDark?: boolean;
}

export const Logo: React.FC<LogoProps> = (props) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const heightMap = new Map<string, string>();
  heightMap.set("default", "8vh");
  heightMap.set("appbar", "48px");
  heightMap.set("large", "15vh");

  const height = heightMap.get(props?.height ?? "default");

  var logo = darkLogo;
  if (mode === "light") {
    logo = lightLogo;
  }

  if (props.forceDark) {
    logo = darkLogo;
  }

  return (
    <Box component="img" sx={{ height: height }} src={logo} alt="Glow Logo" />
  );
};

export const OutlookLogo: React.FC = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Box
      component="img"
      sx={{ height: "24px", width: "24px",}}
      src={ mode == "dark" ? outlookLogoDark : outlookLogoLight}
      alt={"Outlook.com"}
    />
  );
};
