import { Box, BoxProps } from '@mui/material'
import { forwardRef } from 'react'

interface GlowFormBoxProps extends BoxProps {
    inDialog?: boolean
}

export const GlowFormBox = forwardRef<HTMLFormElement, GlowFormBoxProps>((props, ref) => {
    const { inDialog, ...rest } = props
    return <Box {...rest} ref={ref} component="form" sx={{
        width: props.inDialog ? undefined : "100%",
        padding: props.inDialog ? undefined : 2,
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        margin: 0,
        ...props.sx,
    }} />
})